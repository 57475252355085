<yc-location-search
  [disabled]="disabled"
  [name]="compKey"
  [tooltipText]="tooltipText"
  [showQuestionMarkForTooltip]="true"
  [placeholder]="placeholder"
  [label]="label"
  [description]="description"
  [srOnlyLabel]="hideLabel"
  [tabIndex]="tabIndex"
  [customLabelIcon]="customLabelIcon"
  [customLabelIconTooltip]="customLabelIconTooltip"
  [customLabelIconClass]="customLabelIconClass"
  [addRequiredAsterisk]="addRequiredAsterisk"
  (onChange)="onChange.emit($event)">
</yc-location-search>

<div *ngIf="!formIsReadOnly && !showExtendedAddressInfo"
  class="mt-2">
  <yc-button
    [link]="true"
    [leftAlignLinkText]="true"
    [langKey]="'common:textCantFindYourAddress'"
    defaultText="Can't find your address?"
    (onClick)="cantFindAddress()">
  </yc-button>
</div>

<div *ngIf="formIsReadOnly && showExtendedAddressInfo && !!data && !isApply"
  class="mt-2 d-flex flex-grow-1">
  <div *ngFor="let craAttribute of extendedAddressAttrs; let last = last;"
    [class.me-2]="!last"
    [ngStyle]="{
      'flex-basis': (100 / (extendedAddressAttrs.length)) + '%'
    }">
    <div>
      <yc-input
        [name]="craAttribute.attribute"
        [label]="craAttribute.label"
        [value]="data[craAttribute.attribute]"
        [disabled]="true">
      </yc-input>
    </div>
  </div>
</div>