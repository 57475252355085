import { coerceElement } from '@angular/cdk/coercion';
import { CdkDragDrop, DragRef, DropListRef, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { BuilderConfig, BuilderConfigGroupType, ComponentTabIndexMap, FormAudience, FormDefinitionComponent, FormDefinitionForUi, FormFieldPasteLocation, FormTypes } from '@features/configure-forms/form.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { ComponentConfigurationModalComponent } from '@features/forms/component-configuration/component-configuration-modal/component-configuration-modal.component';
import { DefaultValType } from '@features/forms/component-configuration/component-configuration.typing';
import { ComponentHelperService, REPORT_FIELD_KEY } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { EvaluationType } from '@features/logic-builder/logic-builder.typing';
import { DragAndDropItem } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService, ModalFactory } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { Panel, PanelSection } from '@yourcause/common/panel';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService, GuidService } from '@yourcause/common/utils';
import { isUndefined } from 'lodash';
import { FormBuilderSpellCheckCorrectionsModalComponent } from '../../form-builder-spell-check-corrections-modal/form-builder-spell-check-corrections-modal.component';
import { FormBuilderState } from '../../form-builder.state';
import { BucketComp, ComponentToSpellCheck, ComponentToViewOrEdit, FormBuilderDropEvent, PartialSpellCheckCorrection, SpellCheckCorrection } from '../../form-builder.typing';
import { FormComponentForImport } from '../form-component-import/form-component-import.service';
import { FormBuilderResources } from './form-builder.resources';

@AttachYCState(FormBuilderState)
@Injectable({ providedIn: 'root' })
export class FormBuilderService  extends BaseYCService<FormBuilderState> {
  readonly bucketId = this.formHelperService.componentBucketIdPrefix +
    '-' +
    this.guidService.short();

  constructor (
    private clientSettingsService: ClientSettingsService,
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private arrayHelperService: ArrayHelpersService,
    private modalFactory: ModalFactory,
    private formHelperService: FormHelperService,
    private guidService: GuidService,
    private componentHelper: ComponentHelperService,
    private formFieldService: FormFieldService,
    private i18n: I18nService,
    private logger: LogService,
    private notifier: NotifierService,
    private spinnerService: SpinnerService,
    private formFieldCategoryService: FormFieldCategoryService,
    private formFieldHelperService: FormFieldHelperService,
    private customDataTablesService: CustomDataTablesService,
    private formBuilderResources: FormBuilderResources,
    private confirmAndTakeAction: ConfirmAndTakeActionService
  ) {
    super();
  }

  get copiedComponent () {
    return this.get('copiedComponent');
  }

  get currentFormBuilderFormAudience () {
    return this.get('currentFormBuilderFormAudience');
  }

  get currentFormBuilderFormId () {
    return this.get('currentFormBuilderFormId');
  }

  get currentFormBuilderDefinition () {
    return this.get('currentFormBuilderDefinition');
  }

  get currentFormBuilderIndex () {
    return this.get('currentFormBuilderIndex');
  }

  get openQuickAddModal () {
    return this.get('openQuickAddModal');
  }

  get openFormFieldImportModal () {
    return this.get('openFormFieldImportModal');
  }

  get inFormBuilder () {
    return this.get('inFormBuilder');
  }

  get componentToViewOrEdit () {
    return this.get('componentToViewOrEdit');
  }

  get formTabWasUpdatedForGoToComp () {
    return this.get('formTabWasUpdatedForGoToComp');
  }

  get isBBGM () {
    return this.clientSettingsService.isBBGM;
  }

  /**
   * Sets the "componentToViewOrEdit" attribute on the state
   *
   * @param value: Value to set
   */
  setComponentToViewOrEdit (value: ComponentToViewOrEdit) {
    this.set('componentToViewOrEdit', value);
  }

  /**
   * Sets the "formTabWasUpdated" attribute on the state
   *
   * @param value: Was the form tab updated?
   */
  setFormTabWasUpdatedForGoToComp (value: boolean) {
    this.set('formTabWasUpdatedForGoToComp', value);
  }

  /**
   * Set open quick add modal
   */
  setOpenQuickAddModal (openQuickAddModal: boolean) {
    this.set('openQuickAddModal', openQuickAddModal);
  }

  setOpenFormFieldImportModal (openFormFieldImportModal: boolean) {
    this.set('openFormFieldImportModal', openFormFieldImportModal);
  }

  /**
   * Sets the current form builder definition
   *
   * @param definition: form definition
   */
  setCurrentFormBuilderDefinition (definition: FormDefinitionForUi[]) {
    this.set('currentFormBuilderDefinition', definition);
    this.formFieldHelperService.setCurrentFormRefFields(definition);
  }

  /**
   * Sets the current form builder audience
   *
   * @param audience: form audience
   */
  setCurrentFormBuilderFormAudience (
    audience: FormAudience
  ) {
    this.set('currentFormBuilderFormAudience', audience);
  }

  /**
   * Sets the current form builder index
   *
   * @param index: form builder tab index
   */
  setCurrentFormBuilderIndex (index: number) {
    this.set('currentFormBuilderIndex', index);
  }

  /**
   * Sets the current form builder form ID
   *
   * @param formId: form id
   */
  setCurrentFormBuilderFormId (formId: number) {
    this.set('currentFormBuilderFormId', formId);
  }

  /**
   * Sets the inFormBuilder attr
   *
   * @param inFormBuilder: are we in the form builder?
   */
  setInFormBuilder (inFormBuilder: boolean) {
    this.set('inFormBuilder', inFormBuilder);
  }

  /**
   * Sets the copiedComponent attr
   *
   * @param component: Copied component to set
   */
  setCopiedComponent (component: FormDefinitionComponent) {
    this.set('copiedComponent', component);
  }

  resetCopiedComponent () {
    this.setCopiedComponent(null);
  }

  /**
   * Returns the component tab index map
   *
   * @param tabs form definition tabs
   * @returns a map with component key on the left, tabIndex & refKey on the right
   */
  getComponentTabIndexMap (tabs: FormDefinitionForUi[]) {
    const componentTabIndexMap: ComponentTabIndexMap = {};
    const compKeyToComponent: Record<string, FormDefinitionComponent> = {};
    tabs.forEach((tab, tabIndex) => {
      this.componentHelper.eachComponent(tab.components, (component) => {
        compKeyToComponent[component.key] = component;
        if (
          this.componentHelper.isReportFieldComp(component.type) &&
          this.componentHelper.isReportNominationField(component.reportFieldDataOptions.reportFieldObject)
        ) {
          const fieldKey = component.reportFieldDataOptions.reportFieldDisplay;
          const nomKey = this.componentHelper.getNominatorReportKey(fieldKey);
          componentTabIndexMap[nomKey] = {
            tabIndex,
            refKey: fieldKey
          };
        } else {
          componentTabIndexMap[component.key] = {
            tabIndex,
            refKey: this.componentHelper.getRefFieldKeyFromCompType(component.type)
          };
        }
      }, true);
    });

    return {
      compKeyToComponent,
      componentTabIndexMap
    };
  }

  /**
   * Given the builder config group type, this returns the panels for that type
   *
   * @param type: builder config group type
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the panels for the given group type
   */
  getPanelsByType (
    type: BuilderConfigGroupType,
    formType: FormTypes,
    formAudience: FormAudience
  ): Panel<never, BucketComp[]>[] {
    switch (type) {
      case BuilderConfigGroupType.Capture:
        return [
          this.getReferenceFieldsPanel(true, formType, formAudience),
          this.isBBGM ? undefined : this.getStandardPanel(formType, formAudience)
        ].filter((item) => !!item);
      case BuilderConfigGroupType.Display:
        return [
          this.getSsoPanel(formAudience),
          this.clientSettingsService.isBBGM ? null : this.getReferenceFieldsPanel(false, formType, formAudience),
          this.isBBGM ? undefined : this.getReportPanel(formType, formAudience),
          this.getCustomContentPanel(formAudience)
        ].filter((item) => !!item);
      case BuilderConfigGroupType.Layout:
        return [
          this.getLayoutPanel(formAudience)
        ].filter((item) => !!item);
      case BuilderConfigGroupType.OnForm:
        return [
          this.getOnFormPanel()
        ].filter((item) => !!item);
    }
  }

  /**
   * Gets the toolbox panel sections for the form builder
   *
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the toolbox panel sections
   */
  getToolboxPanelSections (
    formType: FormTypes,
    formAudience: FormAudience
  ): PanelSection<never, BucketComp[]>[] {
    const groups = this.getBuilderGroups();

    return Object.keys(groups).map<PanelSection<never, BucketComp[]>>((key) => {
      const type = +key as BuilderConfigGroupType;
      const panels = this.getPanelsByType(type, formType, formAudience);

      return {
        name: groups[type],
        panels,
        open: type === BuilderConfigGroupType.Capture
      };
    }).filter((item) => {
      return item.panels.length > 0;
    });
  }

  /**
   * Returns a map of the builder group config and it's label
   *
   * @returns the Builder Group Config
   */
  getBuilderGroups (): BuilderConfig {
    return {
      [BuilderConfigGroupType.Layout]: this.i18n.translate('common:hdrLayout', {}, 'Layout'),
      [BuilderConfigGroupType.Capture]: this.i18n.translate('common:hdrCapture', {}, 'Capture'),
      [BuilderConfigGroupType.Display]: this.i18n.translate('FORMS:textDisplay', {}, 'Display'),
      [BuilderConfigGroupType.OnForm]: this.i18n.translate('common:hdrCurrentlyOnForm', {}, 'Currently on form')
    };
  }

  /**
   * Checks if the array has any visible components
   *
   * @param components: Components to check
   * @returns if there are visible components to show
   */
  hasVisibleComponents (components: BucketComp[]) {
    return components.some((comp) => {
      return !comp.hidden;
    });
  }

  /**
   * Get Panel Obj for Hidden Panels
   *
   * For On Form Panel & Layout Panel
   * The panel itself is hidden because there's only 1 in the group,
   * So it's visible by just showing the section header
   * In this case, we pass a unique ID so it can be differentiated from other panels like this
   *
   * @param type: Builder config type
   * @param components: Components in config
   * @returns the hidden panel object
   */
  getHiddenPanelObj (
    type: BuilderConfigGroupType,
    components: BucketComp[]
  ) {
    return {
      name: '',
      description: '',
      icon: '',
      iconClass: '',
      context: components,
      uniqueId: `${type}`
    };
  }

  /**
   * Returns the layout panel
   *
   * @param formAudience: Form Audience
   * @returns the layout panel
   */
  getLayoutPanel (
    formAudience: FormAudience
  ): Panel<never, BucketComp[]> {
    const components = this.getLayoutComponents(formAudience);
    if (this.hasVisibleComponents(components)) {
      return this.getHiddenPanelObj(BuilderConfigGroupType.Layout, components);
    }

    return null;
  }

  /**
   * Returns the layout components
   *
   * @param formAudience: Form audience
   * @returns the layout components
   */
  getLayoutComponents (
    formAudience: FormAudience
  ): BucketComp[] {
    const layoutComponents: BucketComp[] = [{
      key: 'columns',
      label: this.i18n.translate(
        'common:textColumns',
        {},
        'Columns'
      ),
      name: 'Columns',
      type: 'columns',
      fieldAudience: formAudience,
      icon: this.formFieldHelperService.getFieldIcon('columns'),
      tooltip: '',
      isReferenceField: false,
      markAsRequired: false,
      categoryId: null
    }, {
      key: 'fieldset',
      label: this.i18n.translate(
        'common:textFieldSet',
        {},
        'Field set'
      ),
      name: 'Field Set',
      type: 'fieldset',
      icon: this.formFieldHelperService.getFieldIcon('fieldset'),
      tooltip: '',
      fieldAudience: formAudience,
      isReferenceField: false,
      markAsRequired: false,
      categoryId: null
    }, {
      key: 'panel',
      label: this.i18n.translate(
        'common:textPanel',
        {},
        'Panel'
      ),
      name: 'Panel',
      type: 'panel',
      icon: this.formFieldHelperService.getFieldIcon('panel'),
      tooltip: '',
      fieldAudience: formAudience,
      isReferenceField: false,
      markAsRequired: false,
      categoryId: null
    }, {
      key: 'table',
      label:  this.i18n.translate(
        'FORMS:textTable',
        {},
        'Table'
      ),
      name: 'Table',
      type: 'table',
      icon: this.formFieldHelperService.getFieldIcon('table'),
      tooltip: '',
      fieldAudience: formAudience,
      isReferenceField: false,
      markAsRequired: false,
      categoryId: null
    }, {
      key: 'well',
      label: this.i18n.translate(
        'common:textWell',
        {},
        'Well'
      ),
      name: 'Well',
      type: 'well',
      icon: this.formFieldHelperService.getFieldIcon('well'),
      tooltip: '',
      fieldAudience: formAudience,
      isReferenceField: false,
      markAsRequired: false,
      categoryId: null
    }];

    return this.arrayHelperService.sort(layoutComponents, 'label');
  }

  /**
   * Gets the Refrence Fields Panel
   *
   * @param isCapture: is for capture?
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the reference field panel
   */
  getReferenceFieldsPanel (
    isCapture: boolean,
    formType: FormTypes,
    formAudience: FormAudience
  ): Panel<never, BucketComp[]> {
    let name: string;
    const appResponsesText = this.i18n.translate(
      'common:textApplicantResponses',
      {},
      'Applicant responses'
    );
    const gmResponsesText = this.i18n.translate(
      'common:textGrantManagerResponses',
      {},
      'Grant manager responses'
    );
    if (isCapture) {
      const showApplicantForCapture = formAudience === FormAudience.APPLICANT;
      name = showApplicantForCapture ? appResponsesText : gmResponsesText;
    } else {
      const showManagerForDisplay = formAudience === FormAudience.APPLICANT;
      name = showManagerForDisplay ? gmResponsesText : appResponsesText;
    }
    const components = this.getReferenceFieldComponents(isCapture, formType, formAudience);
    if (this.hasVisibleComponents(components)) {
      return {
        name,
        description: '',
        icon: '',
        iconClass: '',
        context: components
      };
    }

    return null;
  }

  /**
   * Returns the reference field components
   *
   * @param isCapture: is for capture?
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the reference field components
   */
  getReferenceFieldComponents (
    isCapture: boolean,
    formType: FormTypes,
    formAudience: FormAudience
  ): BucketComp[] {
    const fields = this.getFilteredFieldsForBuilder(isCapture, formType, formAudience);

    const components = fields.map<BucketComp>((field) => {
      return this.formFieldHelperService.getReferenceFieldBucketComp(
        field,
        this.formFieldCategoryService.categoryNameMap,
        this.customDataTablesService.customDataTables,
        this.currentFormBuilderDefinition
      );
    });

    return this.arrayHelperService.sort(components, 'name');
  }

  /**
   * Returns the filtered fields for the builder
   *
   * @param isCapture: is for capture?
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the filtered fields
   */
  getFilteredFieldsForBuilder (
    isCapture: boolean,
    formType: FormTypes,
    formAudience: FormAudience
  ): ReferenceFieldAPI.ReferenceFieldDisplayModel[] {
    const isRoutingOrEligibility = [
      FormTypes.ROUTING,
      FormTypes.ELIGIBILITY
    ].includes(formType);

    return this.formFieldHelperService.allReferenceFields.filter((field) => {
      let audiencePassed = false;
      if (isCapture) {
        // We capture responses based on the same audience
        audiencePassed = field.formAudience === formAudience;
      } else {
        // We display fields of the opposite audience that are single response
        audiencePassed = field.formAudience !== formAudience &&
          field.isSingleResponse;
      }

      const nonRoutingOrEligibilityFields = [
        ReferenceFieldsUI.ReferenceFieldTypes.FileUpload,
        ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI,
        ReferenceFieldsUI.ReferenceFieldTypes.Aggregate,
        ReferenceFieldsUI.ReferenceFieldTypes.Table,
        ReferenceFieldsUI.ReferenceFieldTypes.Subset
      ];
      const fieldPassed = isRoutingOrEligibility ?
        !nonRoutingOrEligibilityFields.includes(field.type) :
        true;
      const isValidType = ![
        ReferenceFieldsUI.ReferenceFieldTypes.DataPoint
      ].includes(field.type);
      const notTableColumn = !field.isTableField;

      return audiencePassed && fieldPassed && isValidType && notTableColumn;
    });
  }

  /**
   * Returns the standard components panel
   *
   * @param formType: Form type
   * @param formAudience: Form audience
   * @returns the standard panel
   */
  getStandardPanel (
    formType: FormTypes,
    formAudience: FormAudience
  ): Panel<never, BucketComp[]> {
    const components = this.getStandardComponents(formType, formAudience, []);
    if (this.hasVisibleComponents(components)) {
      return {
        name: this.i18n.translate(
          'common:hdrStandardComponents',
          {},
          'Standard components'
        ),
        description: '',
        icon: '',
        iconClass: '',
        context: components
      };
    }

    return null;
  }

  /**
   * Returns the standard components
   *
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the standard components
   */
  getStandardComponents (
    formType: FormTypes,
    formAudience: FormAudience,
    componentsTypesToAdd: string[]
  ): BucketComp[] {
    const standardComponents = this.getStandardCompsArray(formType, formAudience).filter((comp) => {
      return comp.visible;
    }).map((comp) => {
      return {
        key: comp.key,
        type: comp.type,
        name: comp.name,
        label: comp.label,
        categoryId: null,
        markAsRequired: comp.markAsRequired,
        icon: this.formFieldHelperService.getFieldIcon(comp.type),
        tooltip: '',
        fieldAudience: formAudience,
        isReferenceField: false,
        hidden: !this.componentHelper.componentIsAvailableToAdd(
          comp.type,
          componentsTypesToAdd,
          this.currentFormBuilderDefinition
        )
      };
    });

    return this.arrayHelperService.sort(standardComponents, 'label');
  }

  getStandardCompsArray (
    formType: FormTypes,
    formAudience: FormAudience
  ) {
    const isEligibility = formType === FormTypes.ELIGIBILITY;
    const isRouting = formType === FormTypes.ROUTING;
    const isNomination = formType === FormTypes.NOMINATION;
    const notRoutingEligibilityOrNom = !isEligibility && !isRouting && !isNomination;

    return [{
      key: 'cashAmountRequested',
      type: 'amountRequested',
      label: this.i18n.translate(
        'GLOBAL:lblCashAmountRequested',
        {},
        'Cash amount requested'
      ),
      name: 'Cash amount requested',
      visible: !isEligibility,
      markAsRequired: true
    }, {
      key: 'inKindAmountRequested',
      type: 'inKindItems',
      label: this.i18n.translate(
        'common:hdrInKindAmountRequested',
        {},
        'In kind amount requested'
      ),
      name: 'In-kind amount requested',
      visible: !isEligibility && !isRouting,
      markAsRequired: false
    }, {
      key: 'attention',
      type: 'careOf',
      label: this.i18n.translate(
        'FORMS:textAttention',
        {},
        'Attention'
      ),
      name: 'Attention',
      visible: notRoutingEligibilityOrNom,
      markAsRequired: false
    }, {
      key: 'designation',
      type: 'designation',
      label: this.i18n.translate(
        'GLOBAL:textDesignation',
        {},
        'Designation'
      ),
      name: 'Designation',
      visible: notRoutingEligibilityOrNom &&
        this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.AllowApplicantPaymentDesignations),
      markAsRequired: true
    }, {
      key: 'specialHandling',
      type: 'specialHandling',
      label: this.i18n.translate(
        'ADMIN:hdrAlternateAddress',
        {},
        'Alternate Address'
      ),
      name: 'Special handling',
      visible: notRoutingEligibilityOrNom &&
        this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.AllowApplicantSpecialHandling),
      markAsRequired: false
    }, {
      key: 'decision2',
      type: 'decision',
      label: this.i18n.translate(
        'GLOBAL:lblDecision',
        {},
        'Decision'
      ),
      name: 'Decision',
      visible: formAudience === FormAudience.MANAGER,
      markAsRequired: true
    }, {
      key: 'reviewerFundingRecommendation',
      type: 'reviewerRecommendedFundingAmount',
      label: this.i18n.translate(
        'GLOBAL:textReviewerRecommendedFundingAmount',
        {},
        'Reviewer recommended funding amount'
      ),
      name: 'Reviewer funding recommendation',
      visible: formAudience === FormAudience.MANAGER,
      markAsRequired: true
    }];
  }

  /**
   * Returns the SSO panel
   *
   * @param formAudience: Form Audience
   * @returns the sso panel
   */
  getSsoPanel (
    formAudience: FormAudience
  ): Panel<never, BucketComp[]> {
    if (this.clientSettingsService.clientSettings.hasSSO) {
      const components = this.getSsoComponents(formAudience);
      if (this.hasVisibleComponents(components)) {
        return {
          name: this.i18n.translate(
            'common:textEmployeeHRData',
            {},
            'Employee HR data'
          ),
          description: '',
          icon: '',
          iconClass: '',
          context: components
        };
      }
    }

    return null;
  }

  /**
   * Returns the SSO components
   *
   * @param formAudience: Form Audience
   * @returns the SSO components
   */
  getSsoComponents (
    formAudience: FormAudience
  ) {
    // get SSO fields - these can be renamed/relabeled by the client
    const ssoFields = this.employeeSSOFieldsService.employeeSSOFields;
    // adapt to our model for the toolbox
    const components: BucketComp[] = ssoFields.map((field) => {
      const type = this.componentHelper.getEmployeeSsoCompType(field.attr);
      const name = field.name || field.columnName;

      return {
        key: field.attr,
        label: name,
        name,
        type,
        fieldAudience: formAudience,
        icon: this.formFieldHelperService.getFieldIcon(type),
        tooltip: '',
        isReferenceField: false,
        hidden: !this.componentHelper.componentIsAvailableToAdd(type, [], this.currentFormBuilderDefinition),
        markAsRequired: false,
        categoryId: null
      };
    });

    return this.arrayHelperService.sort(components, 'label');
  }

  /**
   * Returns the report panel
   *
   * @param formType: Form Type
   * @param formAudience: Form Audience
   * @returns the report panel
   */
  getReportPanel (
    formType: FormTypes,
    formAudience: FormAudience
  ): Panel<never, BucketComp[]> {
    const isEligibility = formType === FormTypes.ELIGIBILITY;
    const isRouting = formType === FormTypes.ROUTING;
    if (!isEligibility && !isRouting) {
      const reportFieldLabel = this.i18n.translate(
        'common:textReportField',
        {},
        'Report field'
      );
      const type = REPORT_FIELD_KEY;
      const components: BucketComp[] = [{
        key: 'report',
        label: reportFieldLabel,
        name: reportFieldLabel,
        type,
        fieldAudience: formAudience,
        icon: this.formFieldHelperService.getFieldIcon(type),
        tooltip: '',
        isReferenceField: false,
        markAsRequired: false,
        categoryId: null
      }];

      if (this.hasVisibleComponents(components)) {
        return {
          name: this.i18n.translate(
            'common:textReportComponents',
            {},
            'Report components'
          ),
          description: '',
          icon: '',
          iconClass: '',
          context: components
        };
      }
    }

    return null;
  }

  /**
   * Returns the custom content panel
   *
   * @param formAudience: Form Audience
   * @returns the Custom content panel
   */
  getCustomContentPanel (
    formAudience: FormAudience
  ): Panel<never, BucketComp[]> {
    const type = 'content';
    const components: BucketComp[] = [{
      key: 'content',
      label: this.i18n.translate(
        'common:labelContent',
        {},
        'Content'
      ),
      name: 'Content',
      type,
      icon: this.formFieldHelperService.getFieldIcon(type),
      fieldAudience: formAudience,
      tooltip: '',
      isReferenceField: false,
      markAsRequired: false,
      categoryId: null
    }];
    if (this.hasVisibleComponents(components)) {
      return {
        name: this.i18n.translate(
          'common:textCustomText',
          {},
          'Custom text'
        ),
        description: '',
        icon: '',
        iconClass: '',
        context: components
      };
    }

    return null;
  }

  /**
   * Returns the components on form panel
   *
   * @returns the on Form Panel
   */
  getOnFormPanel (): Panel<never, BucketComp[]> {
    const components = this.getOnFormComponents();
    if (this.hasVisibleComponents(components)) {
      return this.getHiddenPanelObj(BuilderConfigGroupType.OnForm, components);
    }

    return null;
  }

  /**
   * Returns components already on the form
   *
   * @returns list of components on the form
   */
  getOnFormComponents (): BucketComp[] {
    const currentFormBuilderComps = this.componentHelper.getAllComponents(this.currentFormBuilderDefinition);
    const comps = currentFormBuilderComps.filter((comp) => {
      return comp.type !== 'button' &&
        comp.type !== 'htmlelement';
    }).map<BucketComp>((comp) => {
      const field = this.formFieldHelperService.getReferenceFieldFromCompType(comp.type);

      return {
        key: comp.key,
        label: comp.label,
        name: comp.label,
        type: comp.type,
        alternateKeyForSerch: field?.key ?? '',
        alternateNameForSearch: field?.name ?? '',
        icon: this.formFieldHelperService.getFieldIcon(comp.type),
        tooltip: this.formFieldHelperService.getFieldTooltip(
          field,
          this.formFieldCategoryService.categoryNameMap,
          this.customDataTablesService.customDataTables
        ),
        fieldAudience: comp.formAudience,
        notDraggable: true,
        isReferenceField: this.componentHelper.isReferenceFieldComp(comp.type),
        categoryId: field?.categoryId,
        markAsRequired: false,
        actions: [{
          icon: 'cog',
          tooltip: this.i18n.translate(
            'CONFIG:btnEditComponent',
            {},
            'Edit component'
          ),
          onClick: (item: DragAndDropItem) => {
            this.setComponentToViewOrEdit({
              isEdit: true,
              compType: item.type,
              compKey: item.key
            });
          }
        }, {
          icon: 'external-link',
          tooltip: this.i18n.translate(
            'common:textGoToComponent',
            {},
            'Go to component'
          ),
          onClick: (item: DragAndDropItem) => {
            this.setComponentToViewOrEdit({
              isEdit: false,
              compType: item.type,
              compKey: item.key
            });
          }
        }]
      };
    });

    return this.arrayHelperService.sort(comps, 'label');
  }

  /**
   * Stores a component on the state
   *
   * @param component The component being copied
   */
  handleCopyComponent (
    component: FormDefinitionComponent
  ) {
    this.setCopiedComponent(component);
  }

  /**
   * Copies the component and removes it from the definition
   *
   * @param component Component to be cut
   * @param formDef Form definition the component is being cut from
   */
  handleCutComponent (
    component: FormDefinitionComponent,
    formDef: FormDefinitionForUi
  ) {
    this.handleCopyComponent(component);
    this.componentHelper.removeOrReplaceFormComponent(component, formDef);
  }

  /**
   * Pastes the copied component relative to `relativeComponent`
   *
   * @param relativeComponent Relative component for paste
   * @param formDef Form definition accepting pasted component
   * @param pasteLocation Where to insert the form component
   */
  handlePasteComponent (
    relativeComponent: FormDefinitionComponent,
    formDef: FormDefinitionForUi,
    pasteLocation: FormFieldPasteLocation
  ) {
    if (this.copiedComponent) {
      this.componentHelper.insertFormComponent(
        this.copiedComponent,
        formDef,
        relativeComponent,
        pasteLocation
      );
      this.setCopiedComponent(null);
    }
  }

  async proceedWithPasteRefFields (
    refComponents: FormDefinitionComponent[],
    pastedComponent: FormDefinitionComponent,
    relativeComponent: FormDefinitionComponent,
    currentFormDefinition: FormDefinitionForUi,
    pasteLocation: FormFieldPasteLocation
  ) {
    // If confirmed, create the new reference field(s) and register them
    const oldKeyToNewKeyMap = await this.formFieldService.handleCopyComponents(
      refComponents
    );

    await this.finishPasteRefFields(
      refComponents,
      pastedComponent,
      relativeComponent,
      oldKeyToNewKeyMap,
      currentFormDefinition,
      pasteLocation
    );
  }

  async finishPasteRefFields (
    refComponents: FormDefinitionComponent[],
    pastedComponent: FormDefinitionComponent,
    relativeComponent: FormDefinitionComponent,
    oldKeyToNewKeyMap: Record<string, string>,
    formDefinition: FormDefinitionForUi,
    pasteLocation: FormFieldPasteLocation
  ) {
    if (!!oldKeyToNewKeyMap) { // create fields passed
      this.componentHelper.insertFormComponent(
        pastedComponent,
        formDefinition,
        relativeComponent,
        pasteLocation
      );
      this.componentHelper.eachComponent(formDefinition.components, (formComp) => {
        // Find the original component by key
        const found = refComponents.find((compToCopy) => {
          return formComp === compToCopy;
        });
        if (!!found) {
          // Find newly created key and update the type
          const oldKey = this.componentHelper.getRefFieldKeyFromCompType(formComp.type);
          const newKey = oldKeyToNewKeyMap[oldKey];
          formComp.type = `referenceFields-${newKey}`;
        }
      });
      this.setCopiedComponent(null);
    }
  }

  /**
   * Adds the newly created reference field to the form
   *
   * @param modalResponse: Modal response
   */
  async addNewFieldToForm (
    modalResponse: ReferenceFieldsUI.ModalReturn,
    isManagerForm: boolean
  ) {
    try {
      // Create the new field
      this.spinnerService.startSpinner();
      const newField = await this.formFieldService.handleCreateOrUpdateField(
        null,
        modalResponse.field,
        modalResponse.tableFields,
        true
      );
      const bucketComp = this.formFieldHelperService.getReferenceFieldBucketComp(
        newField,
        this.formFieldCategoryService.categoryNameMap,
        this.customDataTablesService.customDataTables,
        this.currentFormBuilderDefinition
      );
      this.spinnerService.stopSpinner();
      const updatedComp = await this.generateAndInsertFormComponent(
        isManagerForm,
        modalResponse.isSecondarySave,
        bucketComp
      );

      return updatedComp;
    } catch (e) {
      this.spinnerService.stopSpinner();
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textThereWasAnErrorSaving',
        {},
        'There was an error saving'
      ));

      return null;
    }
  }

  async generateAndInsertFormComponent (
    isManagerForm: boolean,
    skipEditModal: boolean,
    bucketComp: BucketComp,
    formDefinition = this.currentFormBuilderDefinition,
    indexToInsert = this.currentFormBuilderIndex,
    importComponent?: FormComponentForImport
  ) {
    const comp = this.generateComponent(
      formDefinition,
      bucketComp,
      !!importComponent ? importComponent.required : undefined,
      importComponent
    );
    const updatedComp = await this.addNewComponent(
      comp,
      this.currentFormBuilderFormId,
      formDefinition,
      isManagerForm,
      skipEditModal
    );
    if (!!updatedComp) {
      // Insert at the bottom of the current page
      this.componentHelper.insertFormComponent(
        updatedComp,
        formDefinition[indexToInsert]
      );
    }

    return updatedComp;
  }

  /**
   * Insert any number of form components at the bottom of a given tab
   * 
   * @param tab: Form Definition to add components to
   * @param components: Components to Insert
   */
  insertComponents (
    tab: FormDefinitionForUi,
    components: FormDefinitionComponent[]
  ) {
    components.forEach((comp) => {
      this.componentHelper.insertFormComponent(
        comp,
        tab
      );
    });

    return tab;
  }

  /**
   * Adds the newly created reference field component to the form
   *
   * @param newComp: New component to add
   * @param formId: Form ID
   * @param formDef: Form Definition
   * @param skipEditModal: Skip opening the edit modal
   * @returns the adapted component
   */
  async addNewComponent (
    newComp: FormDefinitionComponent,
    formId: number,
    formDef: FormDefinitionForUi[],
    isManagerForm: boolean,
    skipEditModal = false
  ) {
    const adaptedComp: FormDefinitionComponent = {
      ...newComp,
      value: this.formHelperService.getValueFromComponent(
        newComp,
        { referenceFields: {} },
        false
      )
    };
    if (!skipEditModal) {
      return this.editComponentModal(adaptedComp, formId, formDef, isManagerForm, false);
    }

    return adaptedComp;
  }

  /**
   * Opens the modal to edit a component
   *
   * @param component: Component to edit
   * @param formId: Form ID
   * @param formDefinition: Form Definition
   * @param isManagerForm: Are we editing a GM form
   * @param isViewOnly: Is this modal view only?
   * @returns the updated component
   */
  async editComponentModal (
    component: FormDefinitionComponent,
    formId: number,
    formDefinition: FormDefinitionForUi[],
    isManagerForm: boolean,
    isViewOnly: boolean
  ): Promise<FormDefinitionComponent> {
    const updatedComponent = await this.modalFactory.open(
      ComponentConfigurationModalComponent,
      {
        component,
        formId,
        formDefinition,
        isManagerForm,
        isViewOnly
      },
      { class: 'modal-full-size' }
    );

    return updatedComponent;
  }

  /**
   * Generates a form component
   *
   * @param formDef: Form Definition
   * @param bucket: Component from Bucket
   * @param isRequired: Optional required flag to override the bucket
   * @param importComponent: Only passed when importing fields to a form
   * @returns the adapted form component
   */
  generateComponent (
    formDef: FormDefinitionForUi[],
    bucket: BucketComp,
    isRequired?: boolean,
    importComponent?: FormComponentForImport
  ): FormDefinitionComponent {
    const allComps = this.componentHelper.getAllComponents(formDef, true);
    const refField = this.formFieldHelperService.getReferenceFieldFromCompType(bucket.type);
    let clearOnHide = false;
    if (!!refField) {
      clearOnHide = ![
        ReferenceFieldsUI.ReferenceFieldTypes.Table,
        ReferenceFieldsUI.ReferenceFieldTypes.Subset,
        ReferenceFieldsUI.ReferenceFieldTypes.Aggregate
      ].includes(refField.type);
    }
    const isTextOrTextArea = [
      ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
      ReferenceFieldsUI.ReferenceFieldTypes.TextField
    ].includes(refField?.type);
    const isCurrencyOrNumber = [
      ReferenceFieldsUI.ReferenceFieldTypes.Currency,
      ReferenceFieldsUI.ReferenceFieldTypes.Number
    ].includes(refField?.type);

    let comp: FormDefinitionComponent = {
      key: bucket.key,
      label: bucket.label,
      type: bucket.type,
      placeholder: '',
      validate: {
        required: !isUndefined(isRequired) ?
          isRequired :
          bucket.markAsRequired,
        min: isCurrencyOrNumber ? (refField?.defaultMin ?? null) : null,
        max: isCurrencyOrNumber ? (refField?.defaultMax ?? null) : null,
        minLength: isTextOrTextArea ? (refField?.defaultMin ?? null) : null,
        maxLength: isTextOrTextArea ? (refField?.defaultMax ?? null) : null
      },
      tooltip: '',
      title: '',
      legend: '',
      description: '',
      prefix: '',
      suffix: '',
      displayType: AdHocReportingUI.DisplayTypes.TextField,
      clearOnHide
    };

    switch (bucket.type) {
      case 'panel':
      case 'fieldset':
      case 'well':
        comp.components = [];
        comp.input = false;
        break;
      case 'columns':
        comp.columns = [{
          components: [],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0
        } as FormDefinitionComponent, {
          components: [],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0
        } as FormDefinitionComponent],
        comp.input = false;
        break;
      case 'table':
        comp.rows = this.getEmptyGridRows(3, 3);
        comp.input = false;
        break;
      case 'content':
        comp.input = false;
        break;
    }
    if (!!importComponent) {
      comp = this.adaptImportComponent(comp, importComponent, refField);
    }

    comp.key = this.componentHelper.guessKey(comp, allComps);

    return comp;
  }

  adaptImportComponent (
    component: FormDefinitionComponent,
    importComponent: FormComponentForImport,
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel
  ): FormDefinitionComponent {
    const {
      defaultValType,
      supportsDisabled,
      supportsTooltip,
      supportsValidationTab,
      supportsMinMax
    } = this.formFieldHelperService.getEditFormSupportsSettings(field, this.currentFormBuilderFormAudience);
    let validators = component.validate;
    if (!validators.required && importComponent.required) {
      validators.required = importComponent.required;
    }
    if (supportsValidationTab) {
      if (supportsMinMax) {
        validators = {
          ...validators,
          min: importComponent.min,
          max: importComponent.max
        };
      }
      const isTypeText = [ReferenceFieldsUI.ReferenceFieldTypes.TextArea, ReferenceFieldsUI.ReferenceFieldTypes.TextField].includes(field.type);
      if (isTypeText) {
        validators = {
          ...validators,
          minLength: importComponent.min,
          maxLength: importComponent.max,
          minWords: importComponent.minWords,
          maxWords: importComponent.maxWords
        };
      }
    }
    let tooltip = '';
    if (supportsTooltip) {
      tooltip = importComponent.tooltip;
    }
    let disabled = false;
    if (supportsDisabled) {
      disabled = importComponent.disabled;
    }
    let defaultVal = '';
    if (defaultValType !== DefaultValType.None) {
      defaultVal = importComponent.defaultValue;
    }
  
    const comp = {
      ...component,
      label: importComponent.label,
      description: importComponent.description,
      tooltip,
      disabled,
      defaultVal,
      validate: validators
    };
    if (this.clientSettingsService.isBBGM) {
      comp.pullFromBBGM = importComponent.pullFromBBGM;
    }
    if (importComponent.hidden) {
      comp.conditionalLogic = {
        identifier: this.guidService.nonce(),
        useAnd: false,
        conditions: [],
        evaluationType: EvaluationType.AlwaysFalse
      };
    }

    return comp;
  }

  /**
   * Gets a set of empty grid rows
   *
   * @param numRows: Number of rows
   * @param numCols: Number of columns
   * @returns the set of empty grid rows
   */
  getEmptyGridRows (
    numRows: number,
    numCols: number
  ): FormDefinitionComponent[][] {
    const rows: FormDefinitionComponent[][] = [];
    for (let i = 0; i < numRows; i++) {
      const cols = [];
      for (let j = 0; j < numCols; j++) {
        const component = {
          components: []
        } as FormDefinitionComponent;
        cols.push(component);
      }
      rows.push(cols);
    }

    return rows;
  }

  /**
   * Handles the drop event from @angular/cdk/drag-drop
   *
   * @param drop Drop event from CDK
   * @param formDef Full form definition
   * @returns Whether or not the def was changed
   */
  async handleComponentDrop (
    drop: CdkDragDrop<FormDefinitionComponent[]>,
    formId: number,
    formDef: FormDefinitionForUi[],
    isManagerForm: boolean
  ): Promise<FormBuilderDropEvent> {
      const isMovingFromBucket = drop.previousContainer.id === this.bucketId;
      const isMovingWithinContainer = drop.previousContainer.id === drop.container.id &&
        drop.previousIndex !== drop.currentIndex;
      const isMovingBetweenContainers = !isMovingWithinContainer && !isMovingFromBucket;
      // if moving from bucket to a container - copy from bucket to container
      if (isMovingFromBucket) {
        const bucketComp = drop.item.data as BucketComp;
        const newComp = this.generateComponent(formDef, bucketComp);
        const comp = await this.addNewComponent(newComp, formId, formDef, isManagerForm);
        if (comp) {
          const { data } = drop.container;
          data.splice(drop.currentIndex, 0, comp);

          return FormBuilderDropEvent.NewComponent;
        }
      // if dropped within the same container
      } else if (isMovingWithinContainer) {
        // and the index has changed - move within container
        moveItemInArray(
          drop.container.data,
          drop.previousIndex,
          drop.currentIndex
        );
        // otherwise element is moved from one container (not bucket) to another - transfer item over

        return FormBuilderDropEvent.ReorderComponent;
      } else if (isMovingBetweenContainers) {
        transferArrayItem(
          drop.previousContainer.data,
          drop.container.data,
          drop.previousIndex,
          drop.currentIndex
        );

        return FormBuilderDropEvent.ReorderComponent;
      }

    // otherwise, they did not drop the component in a valid spot
    return FormBuilderDropEvent.InvalidDrop;
  }

  /**
   * Do AI Spell Check
   *
   * @returns the components that have spelling updates
   */
  doSpellCheck () {
    const allComponents = this.componentHelper.getAllComponents(
      this.currentFormBuilderDefinition,
      false
    );
    if (allComponents.length === 0) {
      this.notifier.error(this.i18n.translate(
        'common:textAddAtLeastOneCompToSpellCheck',
        {},
        'Add at least one component to run the spell check.'
      ));

      return null;
    } else {
      const adaptedComps = allComponents.map<ComponentToSpellCheck>((comp) => {
        return {
          label: comp.label,
          description: comp.description,
          tooltipText: comp.tooltipText,
          type: comp.key,
          errorLabel: comp.errorLabel
        };
      });
      
      return this.formBuilderResources.doSpellCheckOfFormComponents(adaptedComps);
    }
  }

  /**
   * Handle Spell Checking the Current Form Builder Definition
   */
  async handleSpellCheck () {
    const result = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doSpellCheck(),
      '',
      this.i18n.translate(
        'common:textErrorPerformingSpellCheck',
        {},
        'There was an error performing spell check'
      )
    );
    if (result.passed) {
      const response = result.endpointResponse;
      if (!!response) {
        await this.handleSpellCheckChanges(response);
      }
    }
  }

  async handleSpellCheckChanges (components: ComponentToSpellCheck[]) {
    const corrections = this.adaptCorrections(components);
    if (corrections.length === 0) {
      this.notifier.success(this.i18n.translate(
        'common:textAllComponentsPassSpellCheck',
        {},
        'Looks good! All components passed the spell check.'
      ));
    } else {
      const correctionsToProceedWith = await this.modalFactory.open(
        FormBuilderSpellCheckCorrectionsModalComponent,
        {
          corrections
        }
      );
      if (!!correctionsToProceedWith) {
        correctionsToProceedWith.forEach((correction) => {
          const {
            foundComponent,
            tabIndex
          } = this.componentHelper.findComponentByKeyOrType(
            this.currentFormBuilderDefinition,
            correction.component.key
          );
          this.componentHelper.removeOrReplaceFormComponent(
            foundComponent,
            this.currentFormBuilderDefinition[tabIndex],
            this.getComponentWithCorrections(foundComponent, correction)
          );
        });
      }
    }
  }

  getComponentWithCorrections (
    component: FormDefinitionComponent,
    correction: SpellCheckCorrection
  ): FormDefinitionComponent {
    return {
      ...component,
      [correction.attribute]: correction.newVal
    };
  }

  adaptCorrections (
    components: ComponentToSpellCheck[]
  ): SpellCheckCorrection[] {
    return components.reduce((acc, comp) => {
      const {
        foundComponent,
        tabIndex
      } = this.componentHelper.findComponentByKeyOrType(
        this.currentFormBuilderDefinition,
        comp.type
      );

      let returnVal = [...acc];
      const hasLabelUpdate = !!comp.label;
      const hasDescUpdate = !!comp.description;
      const hasTooltipUpdate = !!comp.tooltipText;
      const hasErrorUpdate = !!comp.errorLabel;
      const base: PartialSpellCheckCorrection = {
        component: foundComponent,
        type: foundComponent.type,
        componentTypeTranslated: this.formFieldHelperService.getComponentTypeTranslated(foundComponent),
        pageName: this.currentFormBuilderDefinition[tabIndex].tabName
      };
      if (hasLabelUpdate) {
        returnVal = [
          ...acc,
          {
            ...base,
            attribute: 'label',
            oldVal: base.component.label,
            newVal: comp.label,
            attributeTranslated: this.i18n.translate('common:hdrLabel', {}, 'Label')
          }
        ];
      }
      if (hasDescUpdate) {
        returnVal = [
          ...acc,
          {
            ...base,
            attribute: 'description',
            oldVal: base.component.description,
            newVal: comp.description,
            attributeTranslated: this.i18n.translate('common:textDescription', {}, 'Description')
          }
        ];
      }
      if (hasTooltipUpdate) {
        returnVal = [
          ...acc,
          {
            ...base,
            attribute: 'tooltipText',
            oldVal: base.component.tooltipText,
            newVal: comp.tooltipText,
            attributeTranslated: this.i18n.translate('common:textTooltip', {}, 'Tooltip')
          }
        ];
      }
      if (hasErrorUpdate) {
        returnVal = [
          ...acc,
          {
            ...base,
            attribute: 'errorLabel',
            oldVal: base.component.errorLabel,
            newVal: comp.errorLabel,
            attributeTranslated: this.i18n.translate('common:textCustomErrorMessage', {}, 'Custom error message')
          }
        ];
      }

      return returnVal;
    }, [] as SpellCheckCorrection[]);
  }

  isInsideDomRect (domRect: DOMRect, x: number, y: number) {
    const { top, bottom, left, right } = domRect;
    return y >= top && y <= bottom && x >= left && x <= right;
  }

  //#region nested patch
  // copied from https://github.com/angular/components/pull/21526/files
  patchDragDrop (dropListRef: DropListRef) {
    // A few lines of code used for debugging (saved to avoid having to re-write them)
    const self = this;
    dropListRef._getSiblingContainerFromPosition = function (
      item: DragRef,
      x: number,
      y: number
    ): DropListRef | undefined {
      const targets = [this, ...this['_siblings']];
      // Only consider targets where the drag postition is within the client rect
      // (this avoids calling enterPredicate on each possible target)
      const matchingTargets = targets.filter(ref => {
        return ref._domRect && self.isInsideDomRect(ref._domRect, x, y);
      });
      // Stop if no targets match the coordinates
      if (matchingTargets.length === 0) {
        return undefined;
      }
      // Order candidates by DOM hierarchy and z-index
      const orderedMatchingTargets = self.orderByHierarchy(matchingTargets);
      // The drop target is the last matching target in the list
      const matchingTarget =
        orderedMatchingTargets[orderedMatchingTargets.length - 1];
      // Only return matching target if it is a sibling
      if (matchingTarget === this) {
        return undefined;
      }
      // Can the matching target receive the item?
      if (!matchingTarget._canReceive(item, x, y)) {
        return undefined;
      }
      // Return matching target
      return matchingTarget;
    };
  }

  // Order a list of DropListRef so that for nested pairs, the outer DropListRef
  // is preceding the inner DropListRef. Should probably be ammended to also
  // sort by Z-level.
  orderByHierarchy (refs: DropListRef[]) {
    // Build a map from HTMLElement to DropListRef
    const refsByElement: Map<HTMLElement, DropListRef> = new Map();
    refs.forEach(ref => {
      refsByElement.set(coerceElement(ref.element), ref);
    });
    // Function to identify the closest ancestor among th DropListRefs
    const findAncestor = (ref: DropListRef) => {
      let ancestor = coerceElement(ref.element).parentElement;
      while (ancestor) {
        if (refsByElement.has(ancestor)) {
          return refsByElement.get(ancestor);
        }
        ancestor = ancestor.parentElement;
      }
      return undefined;
    };
    // Node type for tree structure
    interface NodeType { ref: DropListRef; parent?: NodeType; children: NodeType[] }
    // Add all refs as nodes to the tree
    const tree: Map<DropListRef, NodeType> = new Map();
    refs.forEach(ref => {
      tree.set(ref, { ref, children: [] });
    });
    // Build parent-child links in tree
    refs.forEach(ref => {
      const parent = findAncestor(ref);
      if (parent) {
        const node = tree.get(ref);
        const parentNode = tree.get(parent);
        node!.parent = parentNode;
        parentNode!.children.push(node!);
      }
    });
    // Find tree roots
    const roots = Array.from(tree.values()).filter(node => !node.parent);
    // Function to recursively build ordered list from roots and down
    const buildOrderedList = (nodes: NodeType[], list: DropListRef[]) => {
      list.push(...nodes.map(node => node.ref));
      nodes.forEach(node => {
        buildOrderedList(node.children, list);
      });
    };
    // Build and return the ordered list
    const ordered: DropListRef[] = [];
    buildOrderedList(roots, ordered);
    return ordered;
  }
  //#regionend nested patch
}
