import { Component, Input } from '@angular/core';
import { AddressFormGroup } from '@core/components/address-block/address-block.component';
import { FormBuilderFactoryService } from '@core/services/form-builder-factory.service';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'gc-address-form-field-modal',
  templateUrl: './address-form-field-modal.component.html',
  styleUrls: ['./address-form-field-modal.component.scss']
})
export class AddressFormFieldModalComponent extends YCModalComponent<AddressFormGroup> {
  @Input() modalHeader: string;
  @Input() modalSubHeader: string;

  formGroup = this.formBuilderFactory.createAddressGroup({});

  constructor (
    private formBuilderFactory: FormBuilderFactoryService
  ) {
    super();
  }
}

