<div class="pdf-wrapper">
  <div class="full-page form-page">
    <!-- Header -->
    <div class="page-header">
      <div class="left-side-header">
        {{ form.name }}
        <small class="form-desc">
          {{ form.description }}
        </small>
      </div>
      <div *ngIf="revisionVersion"
        class="sub-header">
        {{ revisionVersion }}
      </div>
    </div>

    <div *ngFor="let tab of formComponentsByTab">
      <div class="tab-name">
        {{ tab.tabName }}
      </div>

      <div *ngFor="let component of tab.components"
        class="form-response">

        <div [ngClass]="component.referenceField?.type">
          <!-- Reference Field -->
          <div *ngIf="component.referenceField"
            class="standard-field">
            <ng-template
              *ngTemplateOutlet="label; context: {
                component: component
              }">
            </ng-template>

            <!-- If Options -->
            <div *ngIf="component.referenceField?.options?.length > 0">
              <div class="select-text">
                <yc-i18n
                  [key]="component.referenceField.supportsMultiple ||
                    component.referenceField.type === FieldTypes.SelectBoxes ?
                      'GLOBAL:textSelectMultiple' :
                      'GLOBAL:textSelectOne'"
                  [defaultValue]="component.referenceField.supportsMultiple ||
                    component.referenceField.type === FieldTypes.SelectBoxes ?
                      'Select multiple' :
                      'Select one'">
                </yc-i18n>
              </div>
              <div *ngIf="component.referenceField.notAllOptionsDisplayed">
                (<yc-i18n
                  key="common:textDisplayingFirstNumberOfOptions"
                  [context]="{
                    number: MaxOptionsToDisplay
                  }"
                  defaultValue="Displaying first __number__ options">
                </yc-i18n>)
              </div>
              <yc-checkbox-group
                [name]="component.key"
                [options]="component.referenceField.options">
              </yc-checkbox-group>

            </div>

            <!-- Subsets -->
            <div *ngIf="component.referenceField.type === FieldTypes.Subset">
              <div *ngFor="let dataPoint of component.visibleColumns">
                {{ dataPoint.label }}
              </div>
            </div>

            <!-- Tables -->
            <div *ngIf="component.referenceField.type === FieldTypes.Table">
              <table class="table yc-table">
                <thead>
                  <th *ngFor="let column of component.visibleColumns">
                    {{ column.label }}
                  </th>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Non Reference Fields -->
          <ng-container *ngIf="!component.referenceField">
            <div [ngSwitch]="component.type"
              class="standard-field">

              <!-- Panel -->
              <ng-container *ngSwitchCase="'panel'">
                <div *ngIf="component.title" 
                  class="panel-title">
                  {{ component.title }}
                </div>
              </ng-container>

              <!-- Field Set -->
              <ng-container *ngSwitchCase="'fieldset'">
                <div *ngIf="component.legend"
                  class="panel-title">
                  {{ component.legend }}
                </div>
              </ng-container>

              <!-- Content Components -->
              <ng-container *ngSwitchCase="'content'">
                <div *ngIf="component.html"
                  class="standard-field">
                  <yc-rich-text-display
                    [html]="component.html">
                  </yc-rich-text-display>
                </div>
              </ng-container>

              <!-- Cash Amount Requested -->
              <ng-container *ngSwitchCase="'amountRequested'">
                <ng-template
                  *ngTemplateOutlet="label; context: {
                    component: component
                  }">
                </ng-template>
                <ng-container *ngIf="component.useCustomCurrency === CurrencyRadioOptions.USE_ONE_CURRENCY">
                  ({{ component.customCurrency || defaultCurrency }})
                </ng-container>
              </ng-container>

              <!-- In Kind Amount Requested -->
              <ng-container *ngSwitchCase="'inKindItems'">
                <ng-template
                  *ngTemplateOutlet="label; context: {
                    component: component
                  }">
                </ng-template>
                <div class="select-text">
                  <yc-i18n
                    [key]="component.allowMultiple ?
                      'GLOBAL:textSelectMultiple' :
                      'GLOBAL:textSelectOne'"
                    [defaultValue]="component.allowMultiple ?
                      'Select multiple' :
                      'Select one'">
                  </yc-i18n>
                </div>
                <yc-checkbox-group
                  [name]="component.key"
                  [options]="component.inKindItems">
                </yc-checkbox-group>
              </ng-container>

              <!-- Special Handling -->
              <ng-container *ngSwitchCase="'specialHandling'">
                <ng-template
                  *ngTemplateOutlet="label; context: {
                    component: component
                  }">
                </ng-template>
                <div>
                  {{ component.specialHandlingInstructions }}
                </div>
                <yc-checkbox
                  [name]="component.key"
                  [optionLabel]="SPECIAL_HANDLING_REQUIRED_DESC">
                </yc-checkbox>
              </ng-container>

              <!-- Attention -->
              <ng-container *ngSwitchCase="'careOf'">
                <ng-template
                  *ngTemplateOutlet="label; context: {
                    component: component
                  }">
                </ng-template>
                <yc-checkbox
                  [name]="component.key"
                  [optionLabel]="attentionOptionLabel">
                </yc-checkbox>
              </ng-container>

              <!-- Default -->
              <ng-container *ngSwitchDefault>
                <ng-template
                  *ngTemplateOutlet="label; context: {
                    component: component
                  }">
                </ng-template>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #label
  let-component="component">
  <div *ngIf="!component.hideLabel &&
    (!component.referenceField || component.referenceField.type !== FieldTypes.Checkbox)"
    class="component-label">
    {{ component.label }}
    <!-- Required -->
    <span *ngIf="component.validate.required"
      class="required-label">
      *
    </span>
  </div>

  <div *ngIf="component.referenceField?.type === FieldTypes.Checkbox">
    <yc-checkbox
      [name]="component.key"
      [optionLabel]="component.label"
      [addRequiredAsterisk]="component.validate.required">
    </yc-checkbox>
  </div>

  <small>
    {{ component.description }}
  </small>
</ng-template>