import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { FilterHelpersService, FilterModalTypes, FilterValue } from '@yourcause/common';
import { SelectOption, TypeSafeFormBuilder, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyValue } from '@yourcause/common/currency';
import { DateService } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService, GuidService } from '@yourcause/common/utils';
import { addDays, addWeeks, addYears, isValid, startOfDay, subDays, subWeeks, subYears } from 'date-fns';
import { get, isEqual, isUndefined } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ConditionalLogicResultType, EvaluationType, GlobalLogicGroup, GlobalValueLogicGroup, ListLogicForColumn, ListLogicState, LogicColumn, LogicColumnDisplay, LogicCondition, LogicEvaluationTypeDisplayOptionsConditional, LogicEvaluationTypeDisplayOptionsValidity, LogicFilterTypes, LogicForColumn, LogicGroup, LogicGroupType, LogicRunResult, LogicState, LogicValueFormatType, RelativeDateCalculationConfig } from './logic-builder.typing';
import { LogicStateService } from './logic-state.service';
import { BaseLogicState } from './logic-state.typing';

export type GlobalLogicGroupType<T, V> = GlobalLogicGroup<T>|GlobalValueLogicGroup<T, V>;

@Injectable({ providedIn: 'root' })
export class LogicBuilderService {
  private decimal = new DecimalPipe('en-US');
  operatorOptions: TypeaheadSelectOption[] = [{
    label: `+`,
    value: 'plus'
  }, {
    label: `-`,
    value: 'minus'
  }];

  constructor (
    private logicStateService: LogicStateService,
    private guidService: GuidService,
    private i18n: I18nService,
    private filterHelperService: FilterHelpersService,
    private dateService: DateService,
    private currencyService: CurrencyService,
    private formBuilder: TypeSafeFormBuilder,
    private arrayHelper: ArrayHelpersService
  ) { }

  getResultTypeOptions (
    includeTodaysDate: boolean
  ): TypeaheadSelectOption<ConditionalLogicResultType>[] {
   let options = [{
      label: this.i18n.translate(
        'common:textSpecificValue',
        {},
        'Specific value'
      ),
      value: ConditionalLogicResultType.STATIC_VALUE
    }, {
      label: this.i18n.translate(
        'common:textValueFromAnotherComponent',
        {},
        'Value from another component'
      ),
      value: ConditionalLogicResultType.OTHER_COLUMN
    }];
    if (includeTodaysDate) {
      options = [
        ...options,
        {
          label: this.i18n.translate(
            'common:lblTodaysDate',
            {},
            'Today\'s Date'
          ),
          value: ConditionalLogicResultType.RELATIVE_DATE
       }
      ];
    }

    return this.arrayHelper.sort(options, 'label');
  }

  getConstantUnitOptions (): TypeaheadSelectOption[] {
    return [{
      label: this.i18n.translate('common:lblDays', {}, 'Days'),
      value: 'days'
    }, {
      label: this.i18n.translate('common:lblWeeks', {}, 'Weeks'),
      value: 'weeks'
    }, {
      label: this.i18n.translate('common:lblYears', {}, 'Years'),
      value: 'years'
    }];
  }

  getEvaluationTypeOptionsForPages (): LogicEvaluationTypeDisplayOptionsConditional {
    return [{
      label: this.i18n.translate('FORMS:lblAlwaysShowPage', {}, 'Always show page'),
      value: EvaluationType.AlwaysTrue
    }, {
      label: this.i18n.translate('FORMS:lblAlwaysHidePage', {}, 'Always hide page'),
      value: EvaluationType.AlwaysFalse
    }, {
      label: this.i18n.translate('FORMS:lblShowPageWhen', {}, 'Show page when'),
      value: EvaluationType.ConditionallyTrue
    }, {
      label: this.i18n.translate('FORMS:lblHidePageWhen', {}, 'Hide page when'),
      value: EvaluationType.ConditionallyFalse
    }];
  }

  getEvaluationTypeOptionsForConditional (): LogicEvaluationTypeDisplayOptionsConditional {
    return [{
      label: this.i18n.translate(
        'FORMS:lblAlwaysShowComponent',
        {},
        'Always show component'
      ),
      value: EvaluationType.AlwaysTrue
    }, {
      label: this.i18n.translate(
        'FORMS:lblAlwaysHideComponent',
        {},
        'Always hide component'
      ),
      value: EvaluationType.AlwaysFalse
    }, {
      label: this.i18n.translate(
        'FORMS:lblShowComponentWhen',
        {},
        'Show component when'
      ),
      value: EvaluationType.ConditionallyTrue
    }, {
      label: this.i18n.translate(
        'FORMS:lblHideComponentWhen',
        {},
        'Hide component when'
      ),
      value: EvaluationType.ConditionallyFalse
    }];
  }


  getEvaluationTypeOptionsForValidity (): LogicEvaluationTypeDisplayOptionsValidity {
    return [{
      label: this.i18n.translate(
        'FORMS:lblAlwaysValid',
        {},
        'Always valid'
      ),
      value: EvaluationType.AlwaysTrue
    }, {
      label: this.i18n.translate(
        'FORMS:lblValidWhen',
        {},
        'Valid when'
      ),
      value: EvaluationType.ConditionallyTrue
    }, {
      label: this.i18n.translate(
        'FORMS:lblInvalidComponentWhen',
        {},
        'Invalid when'
      ),
      value: EvaluationType.ConditionallyFalse
    }];
  }

  getDefaultConditionalLogic<T> (): GlobalLogicGroup<T> {
    const group: GlobalLogicGroup<T> = {
      evaluationType: EvaluationType.AlwaysTrue,
      useAnd: false,
      identifier: this.guidService.nonce(),
      conditions: []
    };

    return group;
  }

  getDefaultConditionalValueLogic<T, V> (
    logicValueFormatType?: LogicValueFormatType
  ): GlobalValueLogicGroup<T, V> {
    let result = null;
    if (logicValueFormatType === 'checkbox') {
      result = false;
    }
    const group: GlobalValueLogicGroup<T, V> = {
      evaluationType: EvaluationType.ConditionallyTrue,
      useAnd: false,
      identifier: this.guidService.nonce(),
      conditions: [],
      result: result as any,
      resultType: ConditionalLogicResultType.STATIC_VALUE
    };

    return group;
  }

  getDefaultCondition<T> (useAnd = false): LogicCondition<T, LogicColumn<T>> {
    return {
      sourceColumn: null,
      value: null,
      comparison: null,
      relatedColumn: null,
      useAnd,
      identifier: this.guidService.nonce()
    };
  }

  /**
   * Kick off the *conditional* logic for every group in a set
   *
   * @param logicGroups Each portion of logic to be run, tuples of a LogicColumn and that column's LogicGroup
   * @param record Record used for evaluation
   *
   * @returns the state of the logic execution, containing a map of each column to its individual state, and a map containing each column's dependent groups. A dependent group is one that could change based on the value of the column
   */
  runConditionalLogic<T extends object> (
    logicGroups: (readonly [LogicColumn<T>, GlobalLogicGroup<T>])[],
    record: T
  ): LogicState<T, boolean> {
    const sourceMap = new Map<string, LogicForColumn<T, boolean>>();
    const dependentMap = new Map<string, LogicForColumn<T, boolean>[]>();
    // go over each group and execute the logic
    logicGroups.forEach(([column, group]) => {
      const {
        result$,
        dependencies,
        previousResult
      } = this.executeConditionalLogicForGroup<T>(
        group,
        record,
        undefined
      );

      const logicForColumn: LogicForColumn<T, boolean> = {
        result$,
        previousResult,
        dependencies,
        group,
        column
      };

      sourceMap.set(column.join('.'), logicForColumn);

      // go over each dependent column and add this group to the map
      dependencies.forEach(dep => {
        let existing = dependentMap.get(dep.join('.')) ?? [];
        existing = [
          ...existing,
          logicForColumn
        ];
        dependentMap.set(dep.join('.'), existing);
      });
    });

    return {
      sourceMap,
      dependentMap
    };
  }


  /**
   * Kick off the logic for every group in a set
   *
   * @param logicGroups Each portion of logic to be run, tuples of a LogicColumn and that column's LogicGroup
   * @param record Record used for evaluation
   *
   * @returns the state of the logic execution, containing a map of each column to its individual state, and a map containing each column's dependent groups. A dependent group is one that could change based on the value of the column
   */
  runValueLogic<T extends object, V> (
    logicGroups: (readonly [LogicColumn<T>, GlobalValueLogicGroup<T, V>[]])[],
    record: T
  ): ListLogicState<T, V> {
    const sourceMap = new Map<string, ListLogicForColumn<T, V>>();
    const dependentMap = new Map<string, ListLogicForColumn<T, V>[]>();
    // go over each group and execute the logic
    logicGroups.forEach(([column, groups]) => {
      const {
        result$,
        dependencies,
        previousResult
      } = this.executeValueLogicForGroup<T, V>(
        groups,
        record,
        undefined
      );

      const logicForColumn: ListLogicForColumn<T, V> = {
        result$,
        dependencies,
        previousResult,
        groups,
        column
      };

      sourceMap.set(column.join('.'), logicForColumn);

      // go over each dependent column and add this group to the map
      dependencies.forEach(dep => {
        let existing = dependentMap.get(dep.join('.')) ?? [];
        existing = [
          ...existing,
          logicForColumn
        ];
        dependentMap.set(dep.join('.'), existing);
      });
    });

    return {
      sourceMap,
      dependentMap
    };
  }

  /**
   * Extract the current value given the current state of logic
   *
   * @param column The column to be looked up
   * @param logicState The current state of all logic
   * @returns The current value of the column. Returns `null` if nothing exists
   */
  getCurrentLogicValueOfColumn<T, R> (
    column: LogicColumn<T>,
    logicState: BaseLogicState<LogicRunResult<T, R>>
  ) {
    return this.logicStateService.getCurrentLogicValueOfColumn<R, LogicRunResult<T, R>>(
      column.join('.'),
      logicState
    );
  }

  /**
   * Re-runs logic for groups that need to be run based on a single changed column
   *
   * @param changedColumn The column that triggered any logic
   * @param logicState The result from the previous execution
   * @param record Record used for evaluation
   *
   * @returns A new logic state
   */
  rerunConditionalLogic<T extends object> (
    changedColumn: LogicColumn<T>,
    logicState: LogicState<T, boolean>,
    record: T
  ) {
    // Get the groups that depend on the value of this column
    const { dependents } = this.logicStateService.getRecordsFromState(
      changedColumn.join('.'),
      logicState
    );
    // execute each one and propagate the result
    dependents.forEach((dependentGroup) => {
      const result = this.evaluateGlobalLogicGroup<T, boolean>(
        dependentGroup.group,
        record
      );

      dependentGroup.previousResult = dependentGroup.result$.value;

      dependentGroup.result$.next(result);
    });

    // return a new object (primarily to trigger angular change detection)
    return {
      ...logicState
    };
  }


  /**
   * Re-runs logic for groups that need to be run based on a single changed column
   *
   * @param changedColumn The column that triggered any logic
   * @param logicState The result from the previous execution
   * @param record Record used for evaluation
   *
   * @returns A new logic state
   */
  rerunValueLogic<T extends object, V> (
    changedColumn: LogicColumn<T>,
    logicState: ListLogicState<T, V>,
    record: T
  ) {
    // Get the groups that depend on the value of this column
    const { dependents } = this.logicStateService.getRecordsFromState(
      changedColumn.join('.'),
      logicState
    );
    // execute each one and propagate the result
    dependents.forEach((dependentGroup) => {
      const result = this.getValueFromConditionalValueGroups<T, V>(
        dependentGroup.groups,
        record
      );

      dependentGroup.previousResult = dependentGroup.result$.value;

      dependentGroup.result$.next(result);
    });

    // return a new object (primarily to trigger angular change detection)
    return {
      ...logicState
    };
  }

  /**
   * Extract dependent columns and run group initially
   *
   * @param group Column's group to be evaluated
   * @param record Record used for evaluation
   * @param currentValue value
   * @returns A BehaviorSubject with the result of the evaluation and all of this group's dependent columns
   */
  executeConditionalLogicForGroup<T extends object> (
    group: GlobalLogicGroup<T>,
    record: T,
    currentValue: boolean
  ): LogicRunResult<T, boolean> {
    // pull out all of the conditions
    const extractedConditions = this.extractConditionsFromGroup<T>(group);
    const dependencies = extractedConditions.reduce<LogicColumn<T>[]>((acc, condition) => {
      // return any column in any condition (plus the related column e.g. columnA == columnB)
      return [
        ...acc,
        ...('relatedColumn' in condition ? [
          condition.relatedColumn,
          condition.sourceColumn
        ] : [
          condition.sourceColumn
        ])
      ].filter(column => column !== null);
    }, []);

    // run the logic
    const result = this.evaluateGlobalLogicGroup<T, boolean>(
      group,
      record
    );

    const result$ = new BehaviorSubject(result);

    // return the result and any dependent columns this logic uses
    // so that we know when to re-run this logic
    return {
      result$,
      dependencies,
      previousResult: currentValue as boolean
    };
  }

  /**
   * Extract dependent columns and run group initially
   *
   * @param groups Column's groups to be evaluated
   * @param record Record used for evaluation
   * @param currentValue Current Value
   * @returns A BehaviorSubject with the result of the evaluation and all of this group's dependent columns
   */
  executeValueLogicForGroup<T extends object, V> (
    groups: GlobalValueLogicGroup<T, V>[],
    record: T,
    currentValue: V
  ): LogicRunResult<T, V> {
    // pull out all of the conditions
    const extractedConditions = this.extractConditionsFromGroups<T, V>(groups);
    const conditionlessDependencies = this.extractConditionlessDependenciesFromGroups<T, V>(groups);
    let dependencies = extractedConditions.reduce<LogicColumn<T>[]>((acc, condition) => {
      // return any column in any condition (plus the related column e.g. columnA == columnB)
      return [
        ...acc,
        ...('relatedColumn' in condition ? [
          condition.relatedColumn,
          condition.sourceColumn
        ] : [
          condition.sourceColumn
        ])
      ].filter(column => column !== null);
    }, []);

    if (conditionlessDependencies) {
      dependencies = [
        ...dependencies,
        ...conditionlessDependencies
      ];
    }

    // run the logic
    const result = this.getValueFromConditionalValueGroups<T, V>(
      groups,
      record
    );

    const result$ = new BehaviorSubject(result);

    // return the result and any dependent columns this logic uses
    // so that we know when to re-run this logic
    return {
      result$,
      dependencies,
      previousResult: currentValue
    };
  }

  extractConditionsFromGroups<T extends object, V> (
    conditionalValueGroups: GlobalValueLogicGroup<T, V>[]
  ): LogicCondition<T, LogicColumn<T>>[] {
    let conditions: LogicCondition<T, LogicColumn<T>>[] = [];
    conditionalValueGroups.forEach((group) => {
      const groupConditions = this.extractConditionsFromGroup(group);
      conditions = [
        ...conditions,
        ...groupConditions.map((_condition) => {
          return _condition;
        })
      ];
    });

    return conditions;
  }

  extractConditionlessDependenciesFromGroups<T extends object, V> (
    conditionalValueGroups: GlobalValueLogicGroup<T, V>[]
  ): LogicColumn<T>[] {
    const columns: LogicColumn<T>[] = [];
    conditionalValueGroups.forEach((group) => {
      if (group.resultType === ConditionalLogicResultType.OTHER_COLUMN) {
        columns.push(group.result as any);
      }
    });

    return columns.filter((column) => !!column);
  }

  /**
   * Get all source/related columns out of the conditions within a group
   *
   * @param group: Group to extract conditions from
   */
  extractConditionsFromGroup<T extends object> (
    group: LogicGroupType<T, any>
  ): LogicCondition<T, LogicColumn<T>>[] {
    return group.conditions.reduce<LogicCondition<T, LogicColumn<T>>[]>((acc, conditionOrGroup) => {
      if ('conditions' in conditionOrGroup) {
        return [
          ...acc,
          ...this.extractConditionsFromGroup<T>(conditionOrGroup)
        ];
      }

      return [
        ...acc,
        conditionOrGroup
      ];
    }, []);
  }

  /**
   * Go over each child condition/group and evaluate the conditions
   *
   * @param group
   * @param record Record used for evaluation
   */
  evaluateConditionalGroup<T extends object> (
    group: LogicGroup<T>,
    record: T
  ): boolean {
    const result = group.conditions.reduce<{
      state: boolean;
      wasAnd: boolean;
    }>((previousResult, conditionOrGroup) => {

      /**
       * This catches:
       *
       * Previous result was an "OR" (wasAnd == false) and the previous result passed (state == true)
       * e.g. `true || thisCondition` doesn't care about `thisCondition` since because of the `true ||`
       *
       * Previous result was an "AND" (wasAnd == true) and the previous result failed (state == false)
       * e.g. `false && thisCondition` doesn't care about `thisCondition` since because of the `false &&`
       *
       * Both of these scenarios mean we can skip this condition
       */
      if (previousResult.wasAnd !== previousResult.state) {
        return {
          state: previousResult.state,
          wasAnd: conditionOrGroup.useAnd
        };
      }

      let pass: boolean;

      // If this item is a group, re-run this function with the child group
      if ('conditions' in conditionOrGroup) {
        pass = this.evaluateConditionalGroup<T>(conditionOrGroup, record);
      } else {
        pass = this.evaluateConditionalCondition<T>(conditionOrGroup, record);
      }

      return {
        state: pass,
        wasAnd: conditionOrGroup.useAnd
      };
    }, {
      state: true,
      wasAnd: true
    }).state;

    return result;
  }

  private getValueFromConditionalValueGroups<T extends object, V> (
    conditionalValueGroups: GlobalValueLogicGroup<T, V>[],
    record: T
  ): V {
    // TODO: this would be a good place to automatically sort conditional value groups
    // first, evaluate the ones where type is NOT always true
    // then evalue the always true
    const passingGroup = conditionalValueGroups.find((group) => {
      return this.evaluateGlobalLogicGroup(group, record);
    });
    if (passingGroup) {
      const hasResultConfig = !!passingGroup.resultConfig?.constant;
      if (passingGroup.resultType === ConditionalLogicResultType.STATIC_VALUE) {
        return passingGroup.result;
      } else if (passingGroup.resultType === ConditionalLogicResultType.OTHER_COLUMN) {
        // this will return the value from whichever field is set in the config
        const valFromOtherField = this.getRecordValue(record, passingGroup.result as any);
        if (!!valFromOtherField) {
          if (hasResultConfig) {
            return this.applyDateCalculation(valFromOtherField, passingGroup.resultConfig) as any;
          } else {
            return valFromOtherField;
          }
        }
      } else if (passingGroup.resultType === ConditionalLogicResultType.RELATIVE_DATE) {
        return this.applyDateCalculation(
          startOfDay(new Date()),
          passingGroup.resultConfig
        ) as any;
      } else {
        return passingGroup.result;
      }
    }

    return '' as any;
  }

  applyDateCalculation (
    value: Date,
    dateConfig: RelativeDateCalculationConfig
  ) {
    if (!!dateConfig) {
      switch (dateConfig.operator) {
        case 'plus':
          switch (dateConfig.constantUnits) {
            case 'days':
              return addDays(value, dateConfig.constant);
            case 'weeks':
              return addWeeks(value, dateConfig.constant);
            case 'years':
              return addYears(value, dateConfig.constant);
          }
          break;
        case 'minus':
          switch (dateConfig.constantUnits) {
            case 'days':
              return subDays(value, dateConfig.constant);
            case 'weeks':
              return subWeeks(value, dateConfig.constant);
            case 'years':
              return subYears(value, dateConfig.constant);
          }
      }
    }

    return value;
  }

  /**
   * Runs the high level group and applies the inverse if set, used for kicking off/re-running logic
   *
   * @param group A column's group
   * @param record Record used for evaluation
   */
 evaluateGlobalLogicGroup<T extends object, V> (
    group: GlobalLogicGroupType<T, V>,
    record: T
  ): boolean {
    if (!group) {
      return true;
    }

    switch (group.evaluationType) {
      case EvaluationType.AlwaysFalse:
        return false;
      case EvaluationType.AlwaysTrue:
        return true;
      case EvaluationType.ConditionallyTrue:
        return this.evaluateConditionalGroup<T>(group, record);
      case EvaluationType.ConditionallyFalse:
        return !this.evaluateConditionalGroup<T>(group,  record);
    }
  }

  /**
   * Extracts values from a single condition and performs the comparisons
   *
   * @param condition The actual condition to be evaluated
   * @param record Record used for evaluation
   */
  evaluateConditionalCondition<T extends object> (
    condition: LogicCondition<T, LogicColumn<T>>,
    record: T
  ) {
    const comparisonValue = this.getComparisonValue(condition, record);
    const recordValue = this.getRecordValue(record, condition.sourceColumn);
    const numericRecordValue = this.getNumericValue(recordValue);
    const arrayRecordValue = this.getArrayRecordValue(recordValue);
    const numericComparisonValue = this.getNumericValue(comparisonValue);
    const comparison: FilterModalTypes = condition.comparison;

    switch (comparison) {
      case FilterModalTypes.isBlank: {
        return this.isEmpty(recordValue);
      }
      case FilterModalTypes.notBlank: {
        return !this.isEmpty(recordValue);
      }
      case FilterModalTypes.startsWith: {
        return (recordValue as string)?.startsWith(comparisonValue) ?? false;
      }
      case FilterModalTypes.endsWith: {
        return (recordValue as string)?.endsWith(comparisonValue) ?? false;
      }
      case FilterModalTypes.contains: {
        return (recordValue as string)?.includes(comparisonValue) ?? false;
      }
      case FilterModalTypes.multiValueIncludes: {
        return (arrayRecordValue).some((val: any) => {
          return (comparisonValue as any[])?.includes(val);
        });
      }
      case FilterModalTypes.multiValueNotIncludes: {
        return (arrayRecordValue).every((val: any) => {
          return !(comparisonValue as any[])?.includes(val);
        });
      }
      case FilterModalTypes.multiValueEquals:
      case FilterModalTypes.multiValueNotEquals: {
        const invert = comparison === FilterModalTypes.multiValueNotEquals;
        let exactMatch = false;
        // Multi picklists use arrays, Select boxes use objects
        if (recordValue instanceof Array) {
          exactMatch = comparisonValue?.every((val: string) => {
            return recordValue?.includes(val);
          }) && recordValue.length === comparisonValue.length;
        } else if (recordValue instanceof Object) {
          const recordValueLength = Object.keys(recordValue ?? {}).filter((item) => {
            return !!item;
          }).length;

          exactMatch = comparisonValue?.every((val: string) => {
            return recordValue[val];
          }) && recordValueLength === comparisonValue.length;
        }

        return invert ? !exactMatch : exactMatch;
      }
      case FilterModalTypes.equals:
      case FilterModalTypes.notEqual:
        return this.handleEqualsOrNotEquals(
          comparison,
          recordValue,
          numericRecordValue,
          comparisonValue,
          numericComparisonValue
        );
      case FilterModalTypes.greaterThan: {
        return numericRecordValue > numericComparisonValue;
      }
      case FilterModalTypes.greaterThanOrEquals: {
        return numericRecordValue >= numericComparisonValue;
      }
      case FilterModalTypes.lessThan: {
        return numericRecordValue < numericComparisonValue;
      }
      case FilterModalTypes.lessThanOrEquals: {
        return numericRecordValue <= numericComparisonValue;
      }
      case FilterModalTypes.between:
      case FilterModalTypes.Today:
      case FilterModalTypes.Tomorrow:
      case FilterModalTypes.LastWeek:
      case FilterModalTypes.ThisWeek:
      case FilterModalTypes.LastMonth:
      case FilterModalTypes.Last6Months:
      case FilterModalTypes.ThisMonth:
      case FilterModalTypes.LastYear:
      case FilterModalTypes.ThisYear:
      case FilterModalTypes.Last30Days:
      case FilterModalTypes.Last365Days:
        return this.evaluateDateComparison(comparison, comparisonValue, recordValue);
    }

    return null;
  }

  /**
   * Returns whether the equals condition passes
   *
   * @param comparison: Comparison type
   * @param recordValue: Record Value
   * @param numericRecordValue: Numeric Record Value
   * @param comparisonValue: Comparison Value
   * @param numericComparisonValue: Numeric Comparison Value
   * @returns if the equals condition passes
   */
  handleEqualsOrNotEquals<T> (
    comparison: FilterModalTypes,
    recordValue: T,
    numericRecordValue: number,
    comparisonValue: T|T[],
    numericComparisonValue: number
  ) {
    let recordValueToPass = numericRecordValue ?? recordValue;
    let comparisonValueToPass = numericComparisonValue ?? comparisonValue;
    const isOneOf = this.isOneOfEquals(recordValueToPass, comparisonValueToPass);
    if (isOneOf) {
      recordValueToPass = recordValue;
      comparisonValueToPass = comparisonValue;
    }
    const passes = this.directEquals(
      recordValueToPass,
      comparisonValueToPass
    );
    if (comparison === FilterModalTypes.notEqual) {
      return !passes;
    } else {
      return passes;
    }
  }

  /**
   * Determine whether the date condition passes or not
   *
   * @param comparison: Comparision Type
   * @param comparisonValue: Comparision Value
   * @param recordValue: Record Value
   * @returns if the condition passes or not
   */
  evaluateDateComparison (
    comparison: FilterModalTypes,
    comparisonValue: any,
    recordValue: any
  ) {
    let compareArray: [Date, Date];
    if (comparison === FilterModalTypes.between) {
      compareArray = comparisonValue;
    } else {
      compareArray = this.filterHelperService.getClientSideRelativeDates(comparison);
    }
    if (recordValue) {
      return this.dateService.isBetween(recordValue, compareArray[0], compareArray[1]);
    }

    return false;
  }

  /**
   *
   * @param record: the record
   * @param sourceColumn: the source column
   * @returns the record value
   */
  getRecordValue<T extends object> (
    record: T,
    sourceColumn: LogicColumn<T>
  ) {
    const value = get(record, sourceColumn);
    if (this.isCurrencyType(value)) {
      return value.amountForControl;
    }

    return value;
  }

  /**
   *
   * @param value: the value
   * @returns if it's a currency type value
   */
  isCurrencyType (value: any): value is CurrencyValue {
    return value instanceof Object &&
      'amountForControl' in value &&
      'currency' in value;
  }

  /**
   *
   * @param condition: the condition
   * @param record: the record to get the value from
   * @returns the value from the record
   */
  getComparisonValue<T extends object> (
    condition: LogicCondition<T, LogicColumn<T>>,
    record: T
  ) {
    const {
      resultType,
      relatedColumn,
      applyCalculation
    } = this.getConditionHelpers(condition);
    let comparisonValue: any = null;
    if (resultType === ConditionalLogicResultType.OTHER_COLUMN) {
      comparisonValue = this.getRecordValue(record, relatedColumn);
    } else if (resultType === ConditionalLogicResultType.STATIC_VALUE) {
      comparisonValue = (condition as any).value;
    } else if (resultType === ConditionalLogicResultType.RELATIVE_DATE) {
      comparisonValue = this.dateService.getStartOrEndOfDayInUtc(new Date().toString());
    }
    if (applyCalculation) {
      comparisonValue = this.applyDateCalculation(comparisonValue, condition.relativeDateCalcConfig);
    }

    return comparisonValue;
  }

  // helpers
  isEmpty (recordValue: any): boolean {
    if (recordValue instanceof Object) {
      return Object.keys(recordValue).length === 0;
    }

    return !recordValue && recordValue !== 0;
  }

  directEquals<T> (
    recordValue: T,
    comparisonValue: T|T[]
  ) {
    // "One of" patch - If the stored comparison value is an array
    // And we are doing an "eq", then it is a oneOf filter
    if (this.isOneOfEquals(recordValue, comparisonValue)) {
      return comparisonValue.some((v) => {
        return isEqual(v, recordValue);
      });
    }

    return recordValue instanceof Array ?
      recordValue.every(value => (comparisonValue as T & any[]).includes(value)) :
      isEqual(recordValue, comparisonValue);
  }

  /**
   * Should we evaluate as "One of" instead of "Equals"
   *
   * @param recordValue: Record Value
   * @param comparisonValue: Comparison Value
   * @returns if we should be doing "One of" logic instead of "Equals"
   */
  isOneOfEquals<T> (
    recordValue: T,
    comparisonValue: T|T[]
  ): comparisonValue is T[] {
    return comparisonValue instanceof Array && !(recordValue instanceof Array);
  }

  getNumericValue (
    value: any
  ): number {
    if (value instanceof Array) {
      return null;
    }

    const numericValue = (value ?? false) ? +value : 0;

    if (!isNaN(numericValue)) {
      return numericValue;
    }

    if (isValid(new Date(value))) {
      return +(new Date(value));
    }

    return null;
  }

  getArrayRecordValue (
    value: any
  ) {
    if (!(value instanceof Array)) {
      return [];
    }

    return value;
  }

  getHasConditionalLogic (evaluationType: EvaluationType) {
    return [
      EvaluationType.ConditionallyTrue,
      EvaluationType.ConditionallyFalse
    ].includes(evaluationType);
  }

  getHasValidConditions<T extends Object, V> (logic: GlobalLogicGroupType<T, V>) {
    if (this.getHasConditionalLogic(logic?.evaluationType)) {
      const conditions = this.extractConditionsFromGroup(logic);

      return conditions.every((condition) => {
        return !!condition.sourceColumn &&
          !!condition.comparison;
      });
    }

    return true;
  }

  hasValidResult<V> (result: V) {
    return !isUndefined(result) &&
      result !== null &&
      (result as any) !== '';
  }

  getLogicAsSentence<T extends Object, V> (
    logic: GlobalLogicGroupType<T, V>,
    availableColumns: LogicColumnDisplay<T>[],
    options: (TypeaheadSelectOption|SelectOption)[],
    logicValueFormatType: LogicValueFormatType,
    noLogicMessage = this.i18n.translate(
      'common:textAddNewRuleOrConditionAlert',
      {},
      'Click "Add new rule" to create a complex set of rules or "Add condition" to create a simple condition'
    ),
    fallbackToNoLogicMessage = false
  ): string {
    const hasConditionalLogic = this.getHasConditionalLogic(logic?.evaluationType);
    const isResult = this.checkLogicIsValueLogic<T, V>(logic);
    if (hasConditionalLogic || isResult) {
      const hasValidResult = isResult ?
        this.hasValidResult((logic as GlobalValueLogicGroup<T, V>).result) :
        true;
      const hasValidConditions = this.getHasValidConditions(logic);
      const setValueIsRelativeDate = (logic as GlobalValueLogicGroup<T, V>)?.resultType === ConditionalLogicResultType.RELATIVE_DATE;
      if (logic.evaluationType === EvaluationType.AlwaysTrue) {
        // if we have a result and it's always TRUE, we are showing a simple statement of that value
        return this.constructSummarySentenceWithResult(
          logic as GlobalValueLogicGroup<T, V>,
          availableColumns,
          options,
          logicValueFormatType
        );
      } else if (logic?.conditions.length === 0) {
        return noLogicMessage;
      } else if (setValueIsRelativeDate) {
        return this.constructSummarySentenceWithResult(
          logic as GlobalValueLogicGroup<T, V>,
          availableColumns,
          options,
          logicValueFormatType
        );
      } else if (hasValidResult && hasValidConditions) {
        if (isResult) {
          return this.constructSummarySentenceWithResult(
            logic as GlobalValueLogicGroup<T, V>,
            availableColumns,
            options,
            logicValueFormatType
          );
        } else {
          return this.constructSummarySentence(
            logic,
            availableColumns
          );
        }
      }
    }

    return fallbackToNoLogicMessage ? noLogicMessage : '';
  }

  checkLogicIsValueLogic<T extends Object, V> (
    logic: GlobalLogicGroupType<T, V>
  ): logic is GlobalValueLogicGroup<T, V> {
    return 'result' in logic;
  }

  constructSummarySentence<T extends Object, V> (
    logic: GlobalLogicGroupType<T, V>,
    availableColumns: LogicColumnDisplay<T>[],
    depth = 0
  ): string {
    const conditionalSentence = `${logic.conditions.reduce((sentence, group, index) => {
      let currentSentence = '';
      if (!('conditions' in group)) {
        // This is a condition
        const foundColumn = availableColumns.find((col) => {
          return group.sourceColumn?.join('.') === col?.column.join('.');
        });
        if (foundColumn) {
          currentSentence = this.getSentenceForCondition(group, foundColumn, availableColumns);
        }
      } else if (group.conditions.length > 0) {
        currentSentence = `<span class="depth-${depth}">(${this.constructSummarySentence(
          group as GlobalLogicGroupType<T, V>,
          availableColumns,
          depth + 1
        )})</span>`;
      }

      sentence += currentSentence;

      const lastCondition = index === logic.conditions.length - 1;
      const addAndOrOr = ('conditions' in group ? group.conditions.length > 0 : true) && !lastCondition;
      if (addAndOrOr) {
        sentence += ` <strong>${this.i18n.translate(
          group.useAnd ? 'GLOBAL:lblAND' : 'GLOBAL:lblOR',
          {},
          group.useAnd ? 'AND' : 'OR'
        )}</strong> `;
      }

      return sentence;
    }, '')}`;


    return conditionalSentence;
  }

  /**
   * Returns a sentence summary of the Condition
   *
   * @param condition: Condition to turn into a sentence
   * @param foundColumn: Related Column
   * @param availableColumns: Available Columns
   * @returns the sentence based on the condition
   */
  getSentenceForCondition<T extends Object, V> (
    condition: LogicCondition<T, LogicColumn<T>>,
    foundColumn: LogicColumnDisplay<T>,
    availableColumns: LogicColumnDisplay<T>[]
  ) {
    const filterDisplay = this.getFilterDisplay(condition, foundColumn.type);
    const {
      applyCalculation,
      resultType,
      relatedColumn
    }  = this.getConditionHelpers(condition);
    let value: any = '';
    if (resultType === ConditionalLogicResultType.OTHER_COLUMN) {
      const foundRelatedColumn = availableColumns.find((col) => {
        return relatedColumn.join('.') === col?.column.join('.');
      });
      if (foundRelatedColumn) {
        value = foundRelatedColumn.label;
      }
    } else if (resultType === ConditionalLogicResultType.RELATIVE_DATE) {
      value = this.i18n.translate('common:lblTodaysDate', {}, `Today's Date`);
    } else {
      value = 'value' in condition ? condition.value ?? '' : '';
      value = this.formatValueByType(value, foundColumn);
    }
    if (applyCalculation) {
      value = `${value} ${this.getCalculationSummary(condition.relativeDateCalcConfig)}`;
    }
    value = value ? ` ${value}` : value;

    return `${foundColumn.label} ${filterDisplay}${value}`;
  }

  /**
   * Get the Filter Display Value based on group and column type
   *
   * @param condition: Group to evaluate
   * @param foundColumnType: Column Type
   * @returns the filter display
   */
  getFilterDisplay<T> (
    condition: LogicCondition<T, LogicColumn<T>>,
    foundColumnType: LogicFilterTypes
  ) {
    const filter = this.filterHelperService.getFilterOptionAndValue(
      {
        filterType: condition.comparison,
        filterValue: ('value' in condition ? condition.value : '') as FilterValue
      },
      foundColumnType
    );
    if (filter?.filterOption) {
      return this.i18n.translate(
        filter.filterOption.displayKey,
        {},
        filter.filterOption.display
      ).toLowerCase();
    }

    return '';
  }

  /**
   * Format the value of the given column
   *
   * @param value: Value to format
   * @param column: Column Configuration
   * @returns the formatted value
   */
  formatValueByType<T extends Object, V> (
    value: any,
    column: LogicColumnDisplay<T>
  ) {
    switch (column.type) {
      case 'date':
        value = this.formatDate(value);
        break;
      case 'list':
      case 'multi-list':
      case 'multiListFuzzyText':
        value = this.formatSelect(value, value, 'select', column.filterOptions);
        break;
      default:
        value = value;
    }

    return value;
  }

  /**
   * Get the Calculation Summary Sentence
   *
   * @param resultConfig: Result Config
   * @returns the calculation summary in a sentence
   */
  getCalculationSummary (
    resultConfig: RelativeDateCalculationConfig
  ) {
    const unitOptions = this.getConstantUnitOptions();
    const operatorDisplay = this.operatorOptions.find((opt) => {
      return opt.value === resultConfig.operator;
    }).label;
    const unitDisplay = unitOptions.find((opt) => {
      return opt.value === resultConfig.constantUnits;
    }).label;

    return `${operatorDisplay} ${resultConfig.constant} ${unitDisplay}`;
  }

  constructSummarySentenceWithResult<T extends Object, V> (
    logic: GlobalValueLogicGroup<T, V>,
    availableColumns: LogicColumnDisplay<T>[],
    options: (TypeaheadSelectOption<string>|SelectOption<string>)[],
    logicValueFormatType: LogicValueFormatType,
    depth = 0
  ) {
    const base = this.constructSummarySentence(
      logic,
      availableColumns,
      depth
    );
    const result = (logic as GlobalValueLogicGroup<T, V>).result;
    let value: any;
    // if the result is based on another component, we need to display "value of nameOfComponent"
    if (logic.resultType === ConditionalLogicResultType.OTHER_COLUMN) {
      const columnForSetValue = availableColumns.find((col) => {
        return col.column.join('.') === (logic.result as any)?.join('.');
      });
      if (columnForSetValue) {
        value = this.i18n.translate(
          'common:textValueOfDynamic',
          {
            nameOfComponent: columnForSetValue.label
          },
          'value of __nameOfComponent__'
        );
      } else {
        value = '';
      }
      if (logic?.resultConfig?.constant) {
        value = `${value} ${this.getCalculationSummary(logic.resultConfig)}`;
      }
    } else if (logic.resultType === ConditionalLogicResultType.RELATIVE_DATE) {
      // get the number of days and construct a sentence
      value = this.i18n.translate(
        'common:lblTodaysDate',
        {},
        'Today\'s Date'
      );
      if (logic?.resultConfig?.constant) {
        value = `${value} ${this.getCalculationSummary(logic.resultConfig)}`;
      }
    } else {
      value = this.formatByLogicValueFormatType(value, result, logicValueFormatType, options);
    }
    // Add line breaks if base sentence is present, otherwise only show 'Set value to ___'
    const setValueToText = this.i18n.translate(
      'common:textSetValueTo',
      {},
      'Set value to'
    );
    const sentence = !!base ?
      `${base}<br><br>${setValueToText} ${value}` :
      `${setValueToText} ${value}`;

    return sentence;
  }

  formatByLogicValueFormatType<V> (
    value: any,
    result: V,
    logicValueFormatType: LogicValueFormatType,
    options: (TypeaheadSelectOption<string>|SelectOption<string>)[]
  ) {
    switch (logicValueFormatType) {
      default:
      case 'text':
        value = this.formatText(result as any);
        break;
      case 'select':
        value = this.formatSelect<V>(result, value, logicValueFormatType, options);
        break;
      case 'checkbox':
        value = this.formatCheckbox(result as any);
        break;
      case 'date':
        value = this.formatDate(result as any);
        break;
      case 'number':
        value = this.formatNumber(result as any);
        break;
      case 'currency':
        value = this.formatCurrency(result as any);
        break;
    }

    return value;
  }

  formatSelect<T> (
    result: T,
    value: string,
    logicValueFormatType: LogicValueFormatType,
    options: (TypeaheadSelectOption<string> | SelectOption<string, string>)[]
  ) {
    if (result instanceof Array) {
      value = this.formatValueForArray(
        result,
        logicValueFormatType,
        options
      );
    } else {
      const foundOption = this.getDisplayValueFromOptions(
        result as any,
        options
      );
      if (foundOption) {
        value = foundOption;
      } else {
        value = value;
      }
    }

    return value;
  }

  formatText (value: string|string[]) {
    if (value instanceof Array) {
      return this.formatValueForArray(
        value as string[],
        'text',
        []
      );
    } else {
      return value;
    }
  }

  formatNumber (value: number|number[]) {
    if (value instanceof Array) {
      return this.formatValueForArray(
        value as number[],
        'number',
        []
      );
    } else {
      return this.decimal.transform(value);
    }
  }

  formatCurrency (value: CurrencyValue) {
    if (value) {
      return this.currencyService.formatMoney(value.amountForControl, value.currency);
    }

    return '';
  }

  formatDate (
    value: string|string[]
  ) {
    if (value instanceof Array) {
      value = value.map((val) => {
        return this.dateService.getStartOrEndOfDayInUtcFormatted(
          val.toString()
        );
      });
      value = value.join(' - ');
    } else {
      if (value) {
        value = this.dateService.getStartOrEndOfDayInUtcFormatted(
          value.toString()
        );
      }
    }

    return value;
  }

  formatCheckbox (
    value: string|boolean
  ) {
    if (
      value === true ||
      value === 'true'
    ) {
      return this.i18n.translate(
        'common:textTrue',
        {},
        'True'
      ).toLowerCase();
    } else {
      return this.i18n.translate(
        'common:textFalse',
        {},
        'False'
      ).toLowerCase();
    }
  }

  formatValueForArray (
    result: any[],
    type: LogicValueFormatType,
    options: (TypeaheadSelectOption<string>|SelectOption<string>)[]
  ) {
    return result.reduce((acc, value, index) => {
      return this.accrueAnswersForSummary(
        acc,
        value,
        index,
        result.length,
        options,
        type
      );
    }, '');
  }

  accrueAnswersForSummary<V> (
    accumulator: string,
    value: V,
    index: number,
    totalNumberOfItems: number,
    options: (TypeaheadSelectOption<string>|SelectOption<string>)[],
    type: LogicValueFormatType
  ) {
    let displayValue: string;
    switch (type) {
      case 'select':
        displayValue = this.getDisplayValueFromOptions(
          value as any,
          options
        );
        break;
      case 'number':
        displayValue = this.formatNumber(value as any);
        break;
      case 'text':
        displayValue = this.formatText(value as any);
        break;
    }
    const returnString = `${accumulator}${displayValue}`;
    const isLastItem = (index + 1) === totalNumberOfItems;
      if (isLastItem) {
      return returnString;
    } else {
      return `${returnString}, `;
    }
  }

  getDisplayValueFromOptions (
    value: string,
    options: (TypeaheadSelectOption<string>|SelectOption<string>)[]
  ) {
    const foundOption = options.find((option) => {
      return option.value === value;
    });

    return foundOption?.label || (foundOption as SelectOption)?.display || value;
  }

  /**
   *
   * @param otherColumnOptions: Other column options for condition
   * @param comparison: condition comparison
   * @returns if we should show the other column options selector
   */
  shouldShowOtherColumnSelector (
    otherColumnOptions: TypeaheadSelectOption[],
    comparison: FilterModalTypes
  ) {
    if (otherColumnOptions.length > 0) {
      const nonColumOptionComparisons = [
        FilterModalTypes.notBlank,
        FilterModalTypes.isBlank,
        FilterModalTypes.Yesterday,
        FilterModalTypes.Today,
        FilterModalTypes.Tomorrow,
        FilterModalTypes.LastWeek,
        FilterModalTypes.ThisWeek,
        FilterModalTypes.LastMonth,
        FilterModalTypes.Last6Months,
        FilterModalTypes.ThisMonth,
        FilterModalTypes.LastYear,
        FilterModalTypes.ThisYear,
        FilterModalTypes.Last30Days,
        FilterModalTypes.Last365Days,
        FilterModalTypes.between
      ];

      return !nonColumOptionComparisons.includes(comparison);
    }

    return false;
  }

  /**
   * Creates the Relative Date Calculations Form Group
   *
   * @param relativeDateCalcConfig: Relative Date Calculation Configuration
   * @returns the form group with the given values
   */
  getRelativeDateCalculationsFormGroup (
    relativeDateCalcConfig: RelativeDateCalculationConfig
  ) {
    return this.formBuilder.group<RelativeDateCalculationConfig>({
      operator: [relativeDateCalcConfig?.operator || 'plus', Validators.required],
      constant: [
        relativeDateCalcConfig?.constant || 1,
        [Validators.required, Validators.min(.01)]
      ],
      constantUnits: [relativeDateCalcConfig?.constantUnits || 'days', Validators.required]
    });
  }

  /**
   * Given a condition, return helper attributes
   *
   * @param condition: Condition to evaluate
   * @returns helpers based on the condition
   */
  getConditionHelpers<T> (
    condition: LogicCondition<T, LogicColumn<T>>
  ) {
    const applyCalculation = !!condition.relativeDateCalcConfig?.constant;
    let relatedColumn: LogicColumn<T> = null;
    let resultType = ConditionalLogicResultType.STATIC_VALUE;
    if (condition.resultType === ConditionalLogicResultType.RELATIVE_DATE) {
      resultType = ConditionalLogicResultType.RELATIVE_DATE;
    } else if ('relatedColumn' in condition) {
      if (!!condition.relatedColumn) {
        relatedColumn = condition.relatedColumn;
        resultType = ConditionalLogicResultType.OTHER_COLUMN;
      }
    }

    return {
      applyCalculation,
      relatedColumn,
      resultType
    };
  }

  handleEvaluationTypeChange<T> (
    logicGroups: GlobalLogicGroup<T>,
    evaluationType: EvaluationType
  ) {
    switch (evaluationType) {
      case EvaluationType.AlwaysFalse:
      case EvaluationType.AlwaysTrue:
        // If they choose Always show/hide,
        // We need to clear out any conditions since they are no longer relevant
        logicGroups = {
          ...logicGroups,
          conditions: []
        };
        break;
    }
  
    return {
      ...logicGroups,
      evaluationType
    };
  }
}
