import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormAnswerValues, FormAudience, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { DynamicValidationService } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyValue } from '@yourcause/common/currency';
import { DateService } from '@yourcause/common/date';
import { FileService, TableDataDownloadFormat, YcFile } from '@yourcause/common/files';
import { CSVBoolean, CSVDate, IsEmail, IsNumber, IsOneOf, IsOneOfMulti, IsString, RegexUI, Required, RequiredFormCheckbox } from '@yourcause/common/form-control-validation';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { trim, uniq } from 'lodash';
import { FormFieldTableAndSubsetResources } from '../resources/form-field-table-and-subset.resources';
import { FormFieldTableAndSubsetState } from '../states/form-field-table-and-subset.state';
import { FormFieldHelperService } from './form-field-helper.service';

@Injectable({ providedIn: 'root' })
@AttachYCState(FormFieldTableAndSubsetState)
export class FormFieldTableAndSubsetService extends BaseYCService<FormFieldTableAndSubsetState> {
  private decimal = new DecimalPipe('en-US');

  constructor (
    private currencyService: CurrencyService,
    private dateService: DateService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private logger: LogService,
    private notifier: NotifierService,
    private fileService: FileService,
    private formFieldTableAndSubsetResources: FormFieldTableAndSubsetResources,
    private customDataTablesService: CustomDataTablesService,
    private dynamicValidationService: DynamicValidationService,
    private formFieldHelperService: FormFieldHelperService,
    private componentHelper: ComponentHelperService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService
  ) {
    super();
  }

  get tableColumnsMap () {
    return this.get('tableColumnsMap');
  }

  get dataPointsMap () {
    return this.get('dataPointsMap');
  }

  get applicationFormTableRowsMap () {
    return this.get('applicationFormTableRowsMap');
  }

  get allReferenceFields () {
    return this.formFieldHelperService.allReferenceFields;
  }

  get referenceFieldMap () {
    return this.formFieldHelperService.referenceFieldMap;
  }

  get referenceFieldMapById () {
    return this.formFieldHelperService.referenceFieldMapById;
  }

  get tableFieldModalOpen () {
    return this.get('tableFieldModalOpen');
  }

  /**
   * Get the Related Field Group or Table
   *
   * @param referenceFieldTableId: Reference Field Table ID to check
   * @returns the related field group or table
   */
  getRelatedFieldGroupOrTable (referenceFieldTableId: number) {
    if (!!referenceFieldTableId) {
      return this.formFieldHelperService.allReferenceFields.find((refField) => {
        const isSubsetOrTable = [
          ReferenceFieldsUI.ReferenceFieldTypes.Subset,
          ReferenceFieldsUI.ReferenceFieldTypes.Table
        ].includes(refField.type);
        if (isSubsetOrTable) {
          return refField.referenceFieldTableId === referenceFieldTableId;
        }

        return false;
      });
    }

    return undefined;
  }

  setTableFieldModalOpen (isOpen: boolean) {
    this.set('tableFieldModalOpen', isOpen);
  }

  resetTableColumnsMap (tableId: number) {
    this.set('tableColumnsMap', {
      ...this.tableColumnsMap,
      [tableId]: undefined
    });
  }

  resetDataPointsMap (subsetId: number) {
    this.set('dataPointsMap', {
      ...this.dataPointsMap,
      [subsetId]: undefined
    });
  }

  getColumnsForTable (
    tableId: number,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>,
    filterByShowInTable = false
  ) {
    if (this.tableColumnsMap[tableId]) {
      labelOverrideMap = labelOverrideMap || {};

      return this.tableColumnsMap[tableId].filter((col) => {
        let passesHidden = true;
        if (!col.isRequired) {
          passesHidden = !(hiddenTableColumnKeys || []).includes(col.referenceField.key);
        }

        return (col.showInTable || !filterByShowInTable) && passesHidden;
      }).map((col) =>  {
        return {
          ...col,
          label: labelOverrideMap[col.referenceField.key] || col.label
        };
      });
    }

    return [];
  }

  mapTableResponsesForUi (
    tableResponses: ReferenceFieldsUI.TableResponseForUi[],
    mapped: ReferenceFieldsUI.RefResponseMapForAdapting,
    applicationId: number,
    applicationFormId: number
  ) {
    tableResponses.forEach((response) => {
      const tableField = this.referenceFieldMapById[response.tableReferenceFieldId];
      const adaptedRows = response.rows.map((row) => {
        return {
          ...row,
          columns: row.columns.map((col) => {
            const columnField = this.referenceFieldMap[col.referenceFieldKey];

            return {
              ...col,
              value: this.formFieldHelperService.prepareValueForMapping(col, columnField)
            };
          })
        };
      });
      mapped[tableField.key] = {
        referenceFieldKey: tableField.key,
        referenceFieldId: tableField.referenceFieldId,
        value: adaptedRows,
        numericValue: null,
        dateValue: null,
        currencyValue: null,
        addressValue: null,
        file: null,
        files: [],
        applicationFormId,
        applicationId
      };
    });
  }

  mapTableResponsesForAPI (
    responses: ReferenceFieldsUI.RefResponseMap,
    applicationFormId: number,
    applicationId: number,
    revisionId: number,
    isNew: boolean, /* If new (offline only), we track off revisionId */
    /* because applicationFormId is not defined yet */
    isManagerForm: boolean
  ): ReferenceFieldAPI.TableChangeResponse {
    let updates: ReferenceFieldAPI.TableChangeValues[] = [];
    let deletions: ReferenceFieldAPI.TableDeletion[] = [];
    Object.keys(responses).forEach((key) => {
      const tableId = this.referenceFieldMap[key].referenceFieldId;
      const field = this.formFieldHelperService.referenceFieldMapById[tableId];
      let shouldSaveChanges = true;
      if (isManagerForm) {
        shouldSaveChanges = field.formAudience === FormAudience.MANAGER;
      } else {
        shouldSaveChanges = field.formAudience === FormAudience.APPLICANT;
      }
      if (shouldSaveChanges) {
        const oldTable = this.applicationFormTableRowsMap[
          this.getTableFormKey(
            isNew ? revisionId : applicationFormId,
            tableId
          )
        ];
        if (oldTable) {
          const newTable = responses[key] as ReferenceFieldsUI.TableResponseRowForUi[] ?? [];
          const changes = this.doChangeTrackingForTableRows(
            oldTable,
            newTable,
            applicationFormId,
            applicationId,
            tableId
          );

          if (changes.updates.length > 0) {
            updates = [
              ...updates,
              ...changes.updates
            ];
          }
          if (changes.deletions.length > 0) {
            deletions = [
              ...deletions,
              ...changes.deletions
            ];
          }
        }
      }
    });

    return {
      updates,
      deletions
    };
  }

  doChangeTrackingForTableRows (
    oldTable: ReferenceFieldsUI.TableResponseRowForUi[],
    newTable: ReferenceFieldsUI.TableResponseRowForUi[],
    applicationFormId: number,
    applicationId: number,
    tableReferenceFieldId: number
  ): ReferenceFieldAPI.TableChangeResponse {
    const updates: ReferenceFieldAPI.TableChangeValues[] = [];
    const deletions: ReferenceFieldAPI.TableDeletion[] = [];
    /* Check for updates and additions */
    newTable.forEach((newRow, index) => {
      /* Add new row scenario */
      if (!newRow.rowId) {
        updates.push(this.formatTableRowUpdate(
          newRow,
          null,
          tableReferenceFieldId,
          true,
          index
        ));
      } else {
        const valuesInNew = this.mapRowValues(newRow);
        /* Check for updates to values in row */
        const foundOld = oldTable.find((oldRow) => {
          return oldRow.rowId === newRow.rowId;
        });
        let hasUpdates = false;
        if (foundOld) {
          const valuesInOld = this.mapRowValues(foundOld);
          Object.keys(valuesInNew).forEach((id) => {
            const newValue = valuesInNew[+id];
            const oldValue = valuesInOld[+id];
            if (newValue !== oldValue) {
              hasUpdates = true;
            }
          });
          if (hasUpdates) {
            updates.push(this.formatTableRowUpdate(
              newRow,
              newRow.rowId,
              tableReferenceFieldId,
              false,
              index
            ));
          }
        }
      }
    });

    /* Check for deletions */
    oldTable.forEach((oldRow) => {
      const foundNew = newTable.find((newRow) => {
        return newRow.rowId === oldRow.rowId;
      });
      /* If new row not found, it was deleted */
      if (!foundNew) {
        deletions.push({
          applicationId,
          applicationFormId,
          tableReferenceFieldId,
          rowId: oldRow.rowId
        });
      }
    });

    return {
      updates,
      deletions
    };
  }

  formatTableRowUpdate (
    newRow: ReferenceFieldsUI.TableResponseRowForUi,
    rowId: number,
    tableReferenceFieldId: number,
    isNewRecord: boolean,
    index: number
  ): ReferenceFieldAPI.TableChangeValues {
    return {
      rowId,
      isNewRecord,
      index,
      tableReferenceFieldId,
      values: newRow.columns.map<ReferenceFieldAPI.ApplicationRefFieldResponseForApi>((column) => {
        const key = column.referenceFieldKey;
        const {
          value,
          currencyValue,
          dateValue,
          addressValue
        } = this.formFieldHelperService.adaptResponseValueForApi(
          key,
          column.value
        );

        return {
          referenceFieldKey: key,
          referenceFieldId: column.referenceFieldId,
          value: value ?? '',
          currencyValue,
          numericValue: null,
          dateValue,
          addressValue
        };
      })
    };
  }

  mapRowValues (
    row: ReferenceFieldsUI.TableResponseRowForUi
  ): Record<number, FormAnswerValues> {
    return row.columns.reduce((acc, col) => {
        return {
          ...acc,
          [col.referenceFieldId]: col.value
        };
      }, {});
  }

  /* Returns the tooltip if they are not allowed to :
   1. Uncheck the 'Summarize Data' box on a table column
   2. Or remove a column that is summarizing data */
  getDisableSummarizeDataTooltip (
    summarizeData: boolean,
    tableRefId: number,
    columnRefId: number,
    forDeletingTableColumn = false
  ): string {
    /* Disable the ability to remove an aggregate from table column */
    /* If the aggregate has been used on forms or reports */
    if (summarizeData && tableRefId && columnRefId) {
      const existingAggregate = this.findAggregateForTableColumn(
        tableRefId,
        columnRefId
      );
      if (existingAggregate?.formCount > 0) {
        return this.i18n.translate(
          forDeletingTableColumn ?
            'FORMS:textCannotDeleteTableColumnOnForms' :
            'FORMS:textCannotUncheckSummaryOnForms2',
          {
            count: existingAggregate.formCount
          },
          forDeletingTableColumn ?
            `Cannot remove this table column because it's summarized data is used on __count__ form(s)` :
            `Cannot be unchecked because the summarized data is used on __count__ form(s)`
        );
      } else if (existingAggregate?.usedOnReports) {
        return this.i18n.translate(
          forDeletingTableColumn ?
            'FORMS:textCannotDeleteTableColumnOnReports' :
            'FORMS:textCannotUncheckSummaryOnReports',
          {},
          forDeletingTableColumn ?
            `Cannot remove this table column because it's summarized data is used on at least one report` :
            'Cannot be unchecked because summarized data is used on at least one report'
        );
      }
    }

    return '';
  }

  setAllTableAndSubsetColumnsOnForm (tableRefIds: number[]) {
    tableRefIds = uniq(tableRefIds);

    return Promise.all(tableRefIds.map((id) => {
      const field = this.referenceFieldMapById[id];
      if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
        return this.setColumnsForTable(id);
      } else {
        return this.setDataPointsForSubset(id);
      }
    }));
  }

  async setDataPointsForSubset (subsetId: number) {
    if (!this.dataPointsMap[subsetId]) {
      const fields = await this.getSubsetRows(subsetId);

      this.set('dataPointsMap', {
        ...this.dataPointsMap,
        [subsetId]: this.arrayHelper.sort(fields, 'columnOrder')
      });
    }

    return this.dataPointsMap[subsetId];
  }

  async setColumnsForTable (tableRefId: number) {
    if (!this.tableColumnsMap[tableRefId]) {
      const fields = await this.getTableFields(tableRefId);

      this.set('tableColumnsMap', {
        ...this.tableColumnsMap,
        [tableRefId]: fields
      });
    }

    return this.tableColumnsMap[tableRefId];
  }

  getTableFormKey (
    appFormIdOrRevisionId: number,
    tableReferenceFieldId: number
  ) {
    return `${appFormIdOrRevisionId}_${tableReferenceFieldId}`;
  }

  setApplicationFormTableRowsMap (
    appFormIdOrRevisionId: number,
    tableResponses: ReferenceFieldsUI.RefResponseMap
  ) {
    const additionsToMap = Object.keys(tableResponses).reduce((acc, key) => {
      const field = this.referenceFieldMap[key];
      const response = tableResponses[key] as ReferenceFieldsUI.TableResponseRowForUi[];

      return {
        ...acc,
        [this.getTableFormKey(appFormIdOrRevisionId, field.referenceFieldId)]: response
      };
    }, {});
    const updatedMap: Record<string, ReferenceFieldsUI.TableResponseRowForUi[]> = {
      ...this.applicationFormTableRowsMap,
      ...additionsToMap
    };

    this.set('applicationFormTableRowsMap', updatedMap);
  }

  returnTableRowImportData (
    tableId: number,
    tableRows: ReferenceFieldsUI.TableResponseRowForUiMapped[],
    download: boolean,
    downloadFormat: TableDataDownloadFormat,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>,
    requiredOverrideKeys: string[],
    translations: Record<string, string>
  ) {
    let columns: ReferenceFieldsUI.TableFieldForUi[];
    const refField = this.referenceFieldMapById[tableId];
    let objectsToDownload: Record<string, FormAnswerValues>[] = [];
    if (refField.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
      columns = this.getColumnsForTable(tableId, hiddenTableColumnKeys, labelOverrideMap);
      objectsToDownload = tableRows.map((row) => {
        return columns.reduce((acc, column) => {
          const label = translations[column.label] || column.label;

          return {
            ...acc,
            [label]: row.responses[column.referenceField.key]
          };
        }, {});
      });
    } else if (refField.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      columns = this.dataPointsMap[tableId];
      const row = tableRows[0];
      if (row) {
        const collectionTypeMap = this.getCollectionTypeMap();
        columns.forEach((column) => {
          objectsToDownload.push({
            [refField.name]: column.label,
            [collectionTypeMap[refField.subsetCollectionType]]: row.responses[column.referenceField.key]
          });
        });
      }

    }
    if (objectsToDownload.length > 0) {
      const csv = this.fileService.convertObjectArrayToCSVString(objectsToDownload);
      if (download) {
        this.fileService.downloadByFormat(csv, downloadFormat);
      }

      return csv;
    } else {
      const cdtItemsMap = this.customDataTablesService.getCdtItemsMapForRow(
        columns,
        undefined,
        false
      );
      const ValidationClass = this.getValidationClassForTableRowImport(
        tableId,
        cdtItemsMap,
        hiddenTableColumnKeys,
        labelOverrideMap,
        requiredOverrideKeys,
        translations
      );
      const csv = this.dynamicValidationService.getSample(ValidationClass);
      if (download) {
        this.fileService.downloadByFormat(csv, downloadFormat);
      }

      return csv;
    }
  }

  getValidationClassForTableRowImport (
    tableId: number,
    cdtItemsMap: Record<number, TypeaheadSelectOption[]>,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>,
    requiredOverrideKeys: string[],
    translations: Record<string, string>
  ) {
    const field = this.referenceFieldMapById[tableId];
    let columns = this.getColumnsForTable(tableId, hiddenTableColumnKeys, labelOverrideMap);
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      columns = this.dataPointsMap[tableId];
    }
    requiredOverrideKeys = requiredOverrideKeys || [];
    translations = translations || {};
    const ValidatorClass = class { };
    columns.forEach((column) => {
      const key = column.referenceField.key;
      const label = translations[column.label] || column.label;
      const isRequired = column.isRequired || requiredOverrideKeys.includes(key);
      if (isRequired) {
        if (
          column.referenceField.type === ReferenceFieldsUI.ReferenceFieldTypes.Checkbox
        ) {
          RequiredFormCheckbox()(ValidatorClass.prototype, label);
        } else {
          Required()(ValidatorClass.prototype, label);
        }
      }

      switch (column.referenceField.type) {
        case ReferenceFieldsUI.ReferenceFieldTypes.Checkbox:
          CSVBoolean()(ValidatorClass.prototype, label);
          break;
        case ReferenceFieldsUI.ReferenceFieldTypes.Date:
          CSVDate()(ValidatorClass.prototype, label);
          break;
        case ReferenceFieldsUI.ReferenceFieldTypes.Number:
          IsNumber()(ValidatorClass.prototype, label);
          break;
        case ReferenceFieldsUI.ReferenceFieldTypes.Radio:
        case ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable:
          const cdtItems = cdtItemsMap[column.referenceField.referenceFieldId];
          const mappedItems = (cdtItems || []).map((item) => {
            return item.label;
          });
          if (column.referenceField.supportsMultiple) {
            IsOneOfMulti(mappedItems)(ValidatorClass.prototype, label);
          } else {
            IsOneOf(mappedItems)(ValidatorClass.prototype, label);
          }
          break;
        default:
          if (column.referenceField.formatType === RegexUI.RegexFormattingType.EMAIL) {
            IsEmail()(ValidatorClass.prototype, label);
          }
          IsString()(ValidatorClass.prototype, label);
          break;
      }
    });

    return ValidatorClass;
  }

  getCollectionTypeMap (): Record<ReferenceFieldAPI.DataSetCollectionType, string> {
    const options = this.getSubsetCollectionOptions();

    return options.reduce((acc, option) => {
      return {
        ...acc,
        [option.value]: option.label
      };
    }, {} as Record<ReferenceFieldAPI.DataSetCollectionType, string>);
  }

  getSubsetCollectionOptions (): TypeaheadSelectOption<ReferenceFieldAPI.DataSetCollectionType>[] {
    return this.arrayHelper.sort([{
      label: this.i18n.translate('common:textNumber', {}, 'Number'),
      value: ReferenceFieldAPI.DataSetCollectionType.Number
    }, {
      label: this.i18n.translate('common:textPercentage', {}, 'Percentage'),
      value: ReferenceFieldAPI.DataSetCollectionType.Percent
    }, {
      label: this.i18n.translate('common:textYesOrNo', {}, 'Yes/No'),
      value: ReferenceFieldAPI.DataSetCollectionType.YesOrNo
    }]);
  }

  adaptTableFieldsToFile (
    tableId: number,
    csvRows: Record<string, any>[],
    cdtItemsMap: Record<number, TypeaheadSelectOption[]>,
    translations: Record<string, string>,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>
  ) {
    const adapted: ReferenceFieldsUI.TableImportRow[] = [];
    const columns = this.getColumnsForTable(tableId, hiddenTableColumnKeys, labelOverrideMap);
    csvRows.forEach((row, index) => {
      Object.keys(row).forEach((translatedLabel) => {
        const foundColumn = columns.find((column) => {
          const translatedColumnLabel = translations[column.label] || column.label;

          return translatedColumnLabel === translatedLabel;
        });
        const field = foundColumn.referenceField;
        let value = row[translatedLabel];
        switch (field.type) {
          case ReferenceFieldsUI.ReferenceFieldTypes.Radio:
          case ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable:
            if (field.supportsMultiple) {
              const itemsArray = (value as string).split(',').map((item) => {
                return trim(item);
              });
              value = itemsArray.map((item) => {
                const foundItem = cdtItemsMap[foundColumn.referenceFieldId].find((opt) => {
                  return opt.label === item;
                });

                return foundItem?.value;
              }).filter((item) => !!item).join(',');
            } else {
              value = cdtItemsMap[foundColumn.referenceFieldId].find((items) => {
                return items.label === value;
              })?.value;
            }
            break;
          case ReferenceFieldsUI.ReferenceFieldTypes.Date:
            value = this.formFieldHelperService.adaptResponseDateValueForApi(value, field);
            break;
        }
        adapted.push({
          key: foundColumn.referenceField.key,
          value,
          applicationReferenceFieldTableRow: index
        });
      });
    });
    const csvString = this.fileService.convertObjectArrayToCSVString(adapted);

    return new Blob([csvString], { type: 'text/csv' });
  }

  async doImportTableRows (
    applicationId: number,
    tableId: number,
    formId: number,
    csvRows: Record<string, any>[],
    cdtItemsMap: Record<number, TypeaheadSelectOption[]>,
    translations: Record<string, string>,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>
  ) {
    const file = this.adaptTableFieldsToFile(
      tableId,
      csvRows,
      cdtItemsMap,
      translations,
      hiddenTableColumnKeys,
      labelOverrideMap
    );
    await this.formFieldTableAndSubsetResources.importTableRows(
      applicationId,
      tableId,
      file,
      formId
    );
  }

  async handleImportTableRows (
    applicationId: number,
    tableId: number,
    formId: number,
    csvRows: Record<string, any>[],
    cdtItemsMap: Record<number, TypeaheadSelectOption[]>,
    translations: Record<string, string>,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>
  ) {
    const {
      passed
    } = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doImportTableRows(
        applicationId,
        tableId,
        formId,
        csvRows,
        cdtItemsMap,
        translations,
        hiddenTableColumnKeys,
        labelOverrideMap
      ),
      this.i18n.translate(
        'CONFIG:textSuccessfullyImportedTheFile',
        {},
        'Successfully imported the file'
      ),
      this.i18n.translate(
        'CONFIG:textErrorImportingTheFile',
        {},
        'There was an error importing the file'
      )
    );

    return passed;
  }

  mapRowsForTable (
    rows: ReferenceFieldsUI.TableResponseRowForUi[],
    tableReferenceFieldId: number,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>,
    formatResponses = false,
    skipCurrencyFormatting = false,
    forImport = false
  ) {
    hiddenTableColumnKeys = hiddenTableColumnKeys || [];
    labelOverrideMap = labelOverrideMap || {};
    const isSubset = this.referenceFieldMapById[tableReferenceFieldId].type === ReferenceFieldsUI.ReferenceFieldTypes.Subset;
    const columns = isSubset ?
      this.dataPointsMap[tableReferenceFieldId] :
      this.getColumnsForTable(tableReferenceFieldId, hiddenTableColumnKeys, labelOverrideMap);

    return rows.map<ReferenceFieldsUI.TableResponseRowForUiMapped>((row) => {
      const columnValueMap: Record<string, FormAnswerValues> = {};
      columns.forEach((column) => {
        columnValueMap[column.referenceField.key] = this.getTableColumnResponse(
          row,
          column,
          formatResponses,
          skipCurrencyFormatting,
          forImport
        );
      });

      return {
        ...row,
        responses: columnValueMap
      };
    });
  }

  getColumnsForTableOrSubset (id: number) {
    return this.dataPointsMap[id] || this.tableColumnsMap[id] || [];
  }

  mapRowsForSubset (
    dataRows: ReferenceFieldsUI.TableResponseRowForUi[],
    refId: number,
    defaultTo0: boolean
  ): {
    rowsForTable: ReferenceFieldsUI.DataPointForUI[];
    dataRows: ReferenceFieldsUI.TableResponseRowForUi[];
    dataRowsWereUpdated: boolean;
  } {
    const dataPoints = this.dataPointsMap[refId];
    const rowsForTable: ReferenceFieldsUI.DataPointForUI[] = [];
    let needToResetDataRows = false;

    dataPoints.forEach((dataPoint) => {
      let foundInRows = false;
      dataRows.forEach((row) => {
        row.columns.forEach((column) => {
          if (column.referenceFieldId === dataPoint.referenceFieldId) {
            foundInRows = true;
            rowsForTable.push({
              ...dataPoint,
              value: column.value
            });
          }
        });
      });
      // If this is a new subset, the data won't exist yet, so we need to add a blank record
      if (!foundInRows) {
        needToResetDataRows = true;
        rowsForTable.push({
          ...dataPoint,
          value: defaultTo0 ? 0 : null
        });
      }
    });

    // Align dataRows with rowsForTable
    if (needToResetDataRows) {
      const rowId = dataRows[0]?.rowId ?? null;
      dataRows = [{
        columns: rowsForTable.map<ReferenceFieldAPI.ApplicationRefFieldResponse>((rowForTable) => {
          return {
            referenceFieldId: rowForTable.referenceFieldId,
            referenceFieldKey: rowForTable.referenceField.key,
            value: rowForTable.value,
            dateValue: null,
            currencyValue: null,
            numericValue: null,
            addressValue: null,
            file: null,
            files: [],
            applicationFormId: null,
            applicationId: null
          };
        }),
        rowId
      }];
    }

    return {
      rowsForTable,
      dataRows,
      dataRowsWereUpdated: needToResetDataRows
    };
  }

  getTableColumnResponse (
    row: ReferenceFieldsUI.TableResponseRowForUi,
    column: ReferenceFieldsUI.TableFieldForUi,
    formatResponses = false,
    skipCurrencyFormatting = false,
    forImport = false
  ) {
    const response = row.columns.find((col) => {
      return col.referenceFieldId === column.referenceFieldId;
    });
    const value = response?.value ?? null;

    return this.formatTableResponse(
      row,
      column,
      value,
      formatResponses,
      skipCurrencyFormatting,
      forImport
    );
  }

  formatTableResponse (
    row: ReferenceFieldsUI.TableResponseRowForUiMapped|ReferenceFieldsUI.TableResponseRowForUi,
    column: ReferenceFieldsUI.TableFieldForUi,
    value: FormAnswerValues,
    formatResponses = true,
    skipCurrencyFormatting = false,
    forImport = false
  ) {
    switch (column.referenceField.type) {
      case ReferenceFieldsUI.ReferenceFieldTypes.Date:
        if (!!value && formatResponses) {
          return this.dateService.getStartOrEndOfDayInUtcFormatted(value.toString());
        }

        return value;
      case ReferenceFieldsUI.ReferenceFieldTypes.Number:
        if (!!value && !!formatResponses) {
          return this.decimal.transform(value as number);
        }

        return value;
      case ReferenceFieldsUI.ReferenceFieldTypes.Currency:
        if (!!value) {
          if (skipCurrencyFormatting || forImport) {
            return (value as CurrencyValue).amountForControl;
          } else if (formatResponses) {
            return this.currencyService.formatMoney((value as CurrencyValue).amountForControl);
          }
        }

        return value;
      case ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable:
      case ReferenceFieldsUI.ReferenceFieldTypes.Radio:
        const cdtItemsMap = this.customDataTablesService.getCdtItemsMapForRow(
          [column],
          row,
          true
        );
        if (!!value && formatResponses) {
          return cdtItemsMap[column.referenceFieldId]?.find((option) => {
            return option.value === value;
          })?.label ?? value;
        }

        return value;
      case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
        if (formatResponses) {
          // should always be an array
          if (!value || !(value instanceof Array)) {
            return [];
          }

          return (value as YcFile<File>[]).map((val) => {
            return val.fileUrl;
          });
        }

        return value;
      case ReferenceFieldsUI.ReferenceFieldTypes.Address:
        if (formatResponses || forImport) {
          if (!!value) {
            const addressValue = value as ReferenceFieldAPI.FormFieldAddressResponse;

            return addressValue?.formattedAddress ?? '';
          }
        }

        return value;
      default:
        return value;
    }
  }

  getAvailableTableFields (audience: FormAudience) {
    return this.allReferenceFields.filter((field) => {
      return field.isTableField &&
        !field.aggregateTableReferenceFieldId &&
        field.formAudience === audience;
    });
  }

  getSubsetRowFields (audience: FormAudience) {
    return this.allReferenceFields.filter((field) => {
      return field.type === ReferenceFieldsUI.ReferenceFieldTypes.DataPoint &&
        field.formAudience === audience;
    });
  }

  getTableFieldSelectOptions () {
    return this.arrayHelper.sort(this.allReferenceFields.filter((field) => {
      return field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table &&
        field.referenceFieldTableId;
    }).map((field) => {
      return {
        label: field.name,
        value: field.key
      };
    }), 'label');
  }

  findAggregateForTableColumn (
    tableRefFieldId: number,
    columnRefFieldId: number
  ) {
    return this.allReferenceFields.find((refField) => {
      return !!refField.aggregateType &&
        refField.aggregateTableReferenceFieldId === tableRefFieldId &&
        refField.parentReferenceFieldId === columnRefFieldId;
    });
  }

  async getTableFields (
    referenceFieldId: number
  ): Promise<ReferenceFieldsUI.TableFieldForUi[]> {
    const refField = this.referenceFieldMapById[referenceFieldId];
    let fields: ReferenceFieldAPI.TableField[] = [];
    if (refField.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
      fields = await this.formFieldTableAndSubsetResources.getTableFields(referenceFieldId);
    } else if (refField.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      fields = await this.formFieldTableAndSubsetResources.getSubsetFields(referenceFieldId);
    }
    const adapted = fields.map<ReferenceFieldsUI.TableFieldForUi>((field) => {
      const summarizeData = !!field.aggregateColumnReferenceFieldId;
      const summarizeLabel = this.referenceFieldMapById[
        field.aggregateColumnReferenceFieldId
      ]?.name ?? '';

      return {
        ...field,
        summarizeData,
        summarizeLabel,
        referenceField: this.referenceFieldMapById[field.referenceFieldId]
      };
    });

    return this.arrayHelper.sort(adapted, 'columnOrder');
  }

  async getSubsetRows (refFieldId: number): Promise<ReferenceFieldsUI.DataPointForUI[]> {
    try {
      const rows = await this.getTableFields(refFieldId);
      const adaptedRows = rows.map((row) => {
        return {
          ...row,
          value: null
        };
      });

      return adaptedRows;
    } catch(e) {
      this.logger.error(e);
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorLoadingFieldGroupOptions',
          {},
          'There was an error loading field group options'
        )
      );

      return null;
    }
  }

  getVisibleTableColumns (
    referenceFieldId: number,
    hiddenTableColumnKeys: string[],
    labelOverrideMap: Record<string, string>,
    translations: Record<string, string> = {},
    isForPdf = false
  ) {
    const field = this.referenceFieldMapById[referenceFieldId];
    let columns;
    if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
      columns = this.getColumnsForTable(referenceFieldId, hiddenTableColumnKeys, labelOverrideMap, !isForPdf);
    } else if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      columns = this.dataPointsMap[referenceFieldId];
    };

    if (columns?.length > 0) {
      return columns.map((col) => {
        if (translations) {
          col.label = translations[col.label] || col.label;
        }

        return col;
      });
    }

    return [];
  }

  getAggregateFieldChanges (
    tableFieldName: string,
    tableReferenceFieldId: number,
    tableFields: ReferenceFieldsUI.TableFieldForUi[] = []
  ): {
    fieldsToCreateOrUpdate: ReferenceFieldAPI.CreateUpdateReferenceField[];
    fieldsToRemove: number[];
  } {
    const fieldsToCreateOrUpdate: ReferenceFieldAPI.CreateUpdateReferenceField[] = [];
    const fieldsToRemove: number[] = [];
    const pendingKeysToBeCreated: string[] = [];
    tableFields.forEach((field) => {
      const existingAggregate = this.findAggregateForTableColumn(
        tableReferenceFieldId,
        field.referenceFieldId
      );
      if (field.summarizeData) {
        /* Check if table aggreate field has already been created */
        /* Or label has been updated */
        const needToCreate = !existingAggregate;
        const needToUpdate = existingAggregate &&
          existingAggregate.name !== field.summarizeLabel;
        const refIdToUpdate = needToUpdate ? existingAggregate.referenceFieldId : null;
        if (needToCreate || needToUpdate) {
          const key = this.formFieldHelperService.guessKey(
            field.summarizeLabel,
            pendingKeysToBeCreated
          );
          pendingKeysToBeCreated.push(key);
          const relatedField = this.referenceFieldMapById[field.referenceFieldId];
          fieldsToCreateOrUpdate.push({
            referenceFieldId: refIdToUpdate,
            customDataTableGuid: null,
            tableInfo: null,
            name: field.summarizeLabel,
            defaultLabel: '',
            defaultMin: null,
            defaultMax: null,
            description: `For: Table: ${tableFieldName}, Field: ${relatedField.name}`,
            type: ReferenceFieldsUI.ReferenceFieldTypes.Number,
            key,
            formatType: field.referenceField.formatType,
            supportsMultiple: false,
            categoryId: null,
            formAudience: FormAudience.APPLICANT,
            parentReferenceFieldId: field.referenceFieldId,
            aggregateType: ReferenceFieldAPI.ReferenceFieldAggregateType.Sum,
            isSingleResponse: false,
            isEncrypted: false,
            isMasked: false,
            isTableField: true,
            aggregateTableReferenceFieldId: tableReferenceFieldId,
            subsetCollectionType: null,
            captureExtendedAddressInfo: false,
            isRichText: false
          });
        }
      } else if (existingAggregate) {
        /* Aggregate exists, but we are no longer aggregating so we need to delete */
        fieldsToRemove.push(existingAggregate.referenceFieldId);
      }
    });

    return {
      fieldsToCreateOrUpdate,
      fieldsToRemove
    };
  }

  adaptTableInfoForSave (
    allowImport: boolean,
    tableFields: ReferenceFieldsUI.TableFieldForUi[]
  ): ReferenceFieldAPI.TableInfoForCreate {
    if (tableFields.length > 0) {
      return {
        allowImport,
        tableFields: tableFields.map<ReferenceFieldAPI.TableFieldForCreate>((field, index) => {
          return {
            referenceFieldKey: field.referenceField.key,
            referenceFieldId: field.referenceFieldId,
            isRequired: field.isRequired,
            columnOrder: index,
            showInTable: field.showInTable,
            label: field.label
          };
        })
      };
    }

    return null;
  }

  /**
   * Returns the number of columns on the subset or table field
   *
   * @param field: the reference field
   * @param hiddenTableColumnKeys: keys to hide in the table
   * @returns the number of columsn (for tables and subsets)
   */
  getNumberOfColumns (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    hiddenTableColumnKeys: string[]
  ) {
    if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      return this.dataPointsMap[field.referenceFieldId]?.length ?? 0;
    } else if (field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
      const columns = this.getColumnsForTable(field.referenceFieldId, hiddenTableColumnKeys, {});

      return columns.length;
    }

    return 0;
  }

  async getTableResponses (
    appId: number,
    appFormId: number,
    tableIds?: number[]
  ) {
    const tableResponses: ReferenceFieldsUI.TableResponseForUi[] = [];
    if (appId && appFormId) {
      if (tableIds?.length > 0) {
        await Promise.all(tableIds.map(async (tableId) => {
          const responses = await this.formFieldTableAndSubsetResources.getTableResponses(
            appId,
            appFormId,
            tableId
          );

          tableResponses.push({
            tableReferenceFieldId: tableId,
            rows: responses.map<ReferenceFieldsUI.TableResponseRowForUi>((response) => {
              return {
                rowId: response.rowId,
                columns: response.columns
              };
            })
          });
        }));
      }
    }

    return tableResponses;
  }

  getTableAndSubsetIds (components: FormDefinitionComponent[]) {
    return components.map((component) => {
      const refFieldKey = this.componentHelper.getRefFieldKeyFromCompType(
        component.type
      );
      const foundField = this.formFieldHelperService.getReferenceFieldByKey(
        refFieldKey
      );
      const isTableOrSubset = [
        ReferenceFieldsUI.ReferenceFieldTypes.Table,
        ReferenceFieldsUI.ReferenceFieldTypes.Subset
      ].includes(foundField?.type);

      return isTableOrSubset ?
        foundField?.referenceFieldId :
        undefined;
    }).filter((id) => !!id);
  }
}
