<yc-modal *ngIf="ready"
  [ycHidden]="hideModal"
  [modalHeader]="modalHeader"
  [modalSubHeader]="tableLabel"
  [primaryButtonText]="primaryButtonText"
  [cancelButtonText]="'common:btnCancel' | ycI18n"
  [secondaryButtonText]="secondaryButtonText"
  [primaryDisabled]="primaryDisabled || saving"
  [secondaryDisabled]="secondaryDisabled || saving"
  (onPrimaryClick)="onPrimaryClick()"
  (onSecondaryClick)="onSecondaryClick()"
  (onCancel)="closeModal.emit()">

  <div *ngFor="let column of tableColumns"
    class="mb-3 mt-2">
    <gc-form-component-display
      [formId]="formId"
      [formGroup]="column.formGroup"
      [component]="column.component"
      [disabledOverride]="isView"      
      [notAutoSave]="true"
      [translations]="translations"
      [value]="responseMap[column.referenceField.key]"
      [emitInitialValidity]="true"
      (onValueChange)="onValueChange($event, column.referenceField.key)"
      (onValidChange)="onValidChange($event, column.referenceFieldId)"
      (onModalToggle)="hideModal = $event">
    </gc-form-component-display>
  </div>
</yc-modal>