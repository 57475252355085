import { FormAnswerValues, FormAudience } from '@features/configure-forms/form.typing';
import { SearchAddress } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { ReferenceFieldsUI } from '../ui/reference-fields.typing';
import { RegexUI, Status } from '@yourcause/common/form-control-validation';

export namespace ReferenceFieldAPI {
  export enum DataSetCollectionType {
    Number = 1,
    Percent = 2,
    YesOrNo = 3
  }

  export enum ReferenceFieldAggregateType {
    Sum = 1,
    Max = 2,
    Min = 3,
    Count = 4,
    Average = 5
  }

  export interface ReferenceFieldBaseModel {
    customDataTableGuid: string;
    parentReferenceFieldId: number;
    name: string;
    defaultLabel: string;
    description: string;
    type: ReferenceFieldsUI.ReferenceFieldTypes;
    formatType: RegexUI.RegexFormattingType;
    key: string;
    supportsMultiple: boolean;
    categoryId: number;
    formAudience: FormAudience;
    aggregateType: ReferenceFieldAggregateType;
    isSingleResponse: boolean;
    isEncrypted: boolean;
    isMasked: boolean;
    tableAllowsImport: boolean; // Tables only
    isTableField: boolean;
    aggregateTableReferenceFieldId: number;
    referenceFieldTableId: number;
    isStandardProductField: boolean;
    standardComponentIsPublished: boolean; // Only used Root zone
    subsetCollectionType: DataSetCollectionType;
    captureExtendedAddressInfo?: boolean; // Only for address fields
    usedOnATable: boolean;
    isRichText: boolean;
    defaultMin: number;
    defaultMax: number;
  }

  export interface TableField {
    referenceFieldId: number;
    referenceFieldName: string;
    label: string;
    isRequired: boolean;
    showInTable: boolean;
    columnOrder: number;
    aggregateColumnReferenceFieldId: number;
  }

  export interface ReferenceFieldDisplayModel extends ReferenceFieldBaseModel {
    referenceFieldId: number;
    formCount: number;
    usedOnReports: boolean;
    createdBy: string;
    createDate: string;
    updatedBy: string;
    updateDate: string;
  }

  export interface ReferenceFieldPaginatedModel extends ReferenceFieldDisplayModel {
    canBeDeleted: boolean;
  }

  export interface ReferenceFieldPaginatedModelForUi extends ReferenceFieldPaginatedModel {
    isAggregate: boolean;
    typeMapped: Status;
    notInUse: boolean;
  }

  export type ReferenceFieldModel = ReferenceFieldPaginatedModel|ReferenceFieldDisplayModel;

  export interface ReferenceFieldPdfData extends ReferenceFieldDisplayModel {
    options: TypeaheadSelectOption[];
    notAllOptionsDisplayed: boolean; // For PDF download, we only display the first 25 options
  }

  export interface ReferenceFieldAdHocModel extends ReferenceFieldDisplayModel {
    formIds: number[];
  }

  export interface ApplicationRefFieldResponse {
    referenceFieldKey: string;
    referenceFieldId: number;
    value: FormAnswerValues;
    numericValue: number|null;
    dateValue: string;
    currencyValue: string;
    file: ApplicationRefFieldFile;
    files: ApplicationRefFieldFile[];
    applicationFormId?: number;
    applicationId?: number;
    addressValue: FormFieldAddressResponse;
  }

  export interface FormFieldAddressResponse extends SearchAddress {
    // The backend captures additional CRA attributes if "captureExtendedAddressInfo" is true
    craTractCode?: string;
    craMsaCode?: string;
    craStateCode?: string;
    craCountyCode?: string;
  }

  export interface AddressAttrInfo {
    attribute: keyof ReferenceFieldAPI.FormFieldAddressResponse;
    display: string;
  }

  export interface AddressCraInfo {
    craTractCode: string;
    craMsaCode: string;
    craStateCode: string;
    craCountyCode: string;
  }

  export interface ApplicationRefFieldResponseForApi {
    referenceFieldKey: string;
    referenceFieldId: number;
    value: string;
    numericValue: number;
    dateValue?: string;
    currencyValue: string;
    addressValue: FormFieldAddressResponse;
  }

  export interface ApplicationRefFieldFile {
    fileId: number;
    fileName: string;
    value: string;
  }

  export interface TableChangeValues {
    values: ApplicationRefFieldResponseForApi[];
    rowId: number;
    isNewRecord: boolean;
    tableReferenceFieldId: number;
    index: number;
  }

  export interface TableDeletion {
    applicationId: number;
    applicationFormId: number;
    tableReferenceFieldId:  number;
    rowId: number;
  }

  export interface TableChangeResponse {
    updates: TableChangeValues[];
    deletions: TableDeletion[];
  }

  export interface TableReferenceFieldResponse extends ApplicationRefFieldResponse {
    tableReferenceFieldId: number;
    rowId: number;
  }

  export interface ExportReferenceField {
    id: number;
    name: string;
    description: string;
    type: string;
    key: string;
    formRevisionIds: number[];
    customDataTableGuid: string;
    supportsMultiple: boolean;
    category: {
      id: number;
      name: string;
    };
    formatType: RegexUI.RegexFormattingType;
    formAudience: FormAudience;
    isEncrypted: boolean;
    isMasked: boolean;
    singleResponse: boolean;
    isTableField: boolean;
    captureExtendedAddressInfo: boolean;
    isRichText: boolean;
    tableInfo: TableInfoForCreate;
  }

  export interface BulkCreateReferenceField {
    id: number;
    name: string;
    description: string;
    type: ReferenceFieldsUI.ReferenceFieldTypes;
    key: string;
    picklistId: number;
    parentReferenceFieldKey: string;
    supportsMultiple: boolean;
    formAudience: FormAudience;
    category: {
      id: number;
      name: string;
    };
    aggregateType: ReferenceFieldAggregateType;
    singleResponse: boolean;
    isEncrypted: boolean;
    isMasked: boolean;
    isTableField: boolean;
    formatType: RegexUI.RegexFormattingType;
    subsetCollectionType: DataSetCollectionType;
    tableInfo: TableInfoForCreate;
    aggregateTableReferenceFieldId: number;
    captureExtendedAddressInfo: boolean;
    isRichText: boolean;
    defaultLabel: string;
    defaultMin: number;
    defaultMax: number;
  }


  export interface Category {
    id: number;
    name: string;
    formFieldCount: number;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
    impersonatedBy: string;
    createImpersonatedBy: string;
  }

  export interface MergeFormFieldsApi {
    referenceFieldId1: number;
    referenceFieldId2: number;
    customDataTableGuid: string;
    name: string;
    description: string;
    type: ReferenceFieldsUI.ReferenceFieldTypes;
    key: string;
    supportsMultiple: boolean;
    categoryId: number;
    formAudience: FormAudience;
    priorityReferenceFieldId: number;
  }

  export interface ReferenceFieldDetail extends ReferenceFieldDisplayModel {
    forms?: {
      formId: number;
      formRevisionId: number;
      name: string;
      formRevisionVersion: number;
    }[];
    hasTable: boolean;
    hasWflAutomation: boolean;
    hasResponses: boolean;
  }

  export interface ApplicationResponse {
    applicationId: number;
    referenceField1Response: string;
    referenceField2Response: string;
  }

  export interface CreateUpdateReferenceField  {
    referenceFieldId: number;
    customDataTableGuid: string;
    tableInfo: TableInfoForCreate;
    name: string;
    defaultLabel: string;
    defaultMin: number;
    defaultMax: number;
    description: string;
    type: ReferenceFieldsUI.ReferenceFieldTypes;
    formatType: RegexUI.RegexFormattingType;
    key: string;
    supportsMultiple: boolean;
    categoryId: number;
    formAudience: FormAudience;
    parentReferenceFieldId: number;
    aggregateType: ReferenceFieldAggregateType;
    isSingleResponse: boolean;
    isEncrypted: boolean;
    isMasked: boolean;
    isTableField: boolean;
    aggregateTableReferenceFieldId: number;
    subsetCollectionType: DataSetCollectionType;
    captureExtendedAddressInfo: boolean;
    isRichText: boolean;
  }

  export interface TableInfoForCreate  {
    allowImport: boolean;
    tableFields: TableFieldForCreate[];
  }

  export interface TableFieldForCreate {
    referenceFieldId: number;
    referenceFieldKey: string;
    isRequired: boolean;
    columnOrder: number;
    showInTable: boolean;
    label: string;
  }

  export interface TableResponse {
    rowId: number;
    columns: TableResponseColumn[];
  }

  export type TableResponseColumn = ApplicationRefFieldResponse;

  export interface TableRowForBulkSave {
    referenceFieldValues: ApplicationRefFieldResponseForApi[];
    rowId: number;
  }

  export interface TableUpdatesForBulkSave {
    applicationId: number;
    applicationFormId: number;
    tableReferenceFieldId: number;
    rows: TableRowForBulkSave[];
    removedRows: number[];
  }

  export interface ConvertFieldTypeAffectedAdHocFilter {
    reportName: string;
    reportDescription: string;
    filterType: string;
    filterValue: any;
    userSavedFilterColumnId: number;
    advancedFilter: boolean;
    reportId: number;
    referenceFieldId: number;
    columnName: string;
    columnDisplayName: string;
  }

  export interface ConvertFieldTypeAffectedWflAutomationRules {
    automationRuleSetExpressionId: number;
    automationRuleSetId: number;
    columnName: string;
    comparisonType: number;
    comparisonValue: any;
    name: string;
    description: string;
    applyRulesWithOr: boolean;
    status: number;
    referenceFieldId: number;
  }


  export interface ConvertFieldTypeValidationResponse {
    affectedAdHocFilters: ConvertFieldTypeAffectedAdHocFilter[];
    affectedWflAutomationRules: ConvertFieldTypeAffectedWflAutomationRules[];
    canConvert: boolean;
  }

  export interface AffectedDashboardWidget {
    reportName: string;
    reportId: number;
    reportDescription: string;
    createdByName: string;
  }

  export interface ConvertFieldToEncryptedAffectedAreas {
    affectedWflAutomationRules: ConvertFieldTypeAffectedWflAutomationRules[];
    affectedAdHocFilters: ConvertFieldTypeAffectedAdHocFilter[];
    affectedDashboardWidgets: AffectedDashboardWidget[];
  }

  export interface ConvertFieldToEncryptedAffectedAreasForUi {
    canConvert: boolean;
    affectedWflAutomationRules: ConvertFieldTypeAffectedWflAutomationRules[];
    affectedAdHocFilters: ConvertFieldTypeAffectedAdHocFilter[];
    affectedDashboardWidgets: AffectedDashboardWidget[];
  }

  export interface ConvertFieldResponseTypeAffectedResponse {
    applicationId: number;
    response: string;
    responseDate: string;
    responseBy: string;
    willBeKept: boolean;
  }

  export interface ConvertFieldResponseTypeValidationResponse {
    affectedResponses: ConvertFieldResponseTypeAffectedResponse[];
    affectedAdHocFilters: ConvertFieldTypeAffectedAdHocFilter[];
    affectedWflAutomationRules: ConvertFieldTypeAffectedWflAutomationRules[];
    canConvert: boolean;
  }

  export interface ConvertFieldMultiToSingleValidationResponse {
    canConvert: boolean;
    affectedResponses: ConvertFieldResponseTypeAffectedResponse[];
  }


  export interface ConvertFieldMultiToSingleValidationResponseForUi extends ConvertFieldMultiToSingleValidationResponse {
    affectedAdHocFilters: ConvertFieldTypeAffectedAdHocFilter[];
    affectedWflAutomationRules: ConvertFieldTypeAffectedWflAutomationRules[];
  }

  export interface ConvertTextToDateValidationResponse extends ConvertFieldTypeValidationResponse {
    invalidDateValues: InvalidDateRecord[];
  }

  export interface InvalidDateRecord {
    invalidDateValue: string;
    formIds: number[];
    applicationReferenceFieldId: number;
    applicationId: number;
  }

  export enum TextFieldConversionType {
    TEXT_AREA = 2,
    TEXT_FIELD = 3
  }

  export interface ConvertNumberToCurrencyValidationResponse {
    canConvert: boolean;
    affectedForms: string[];
  }

  export interface ConvertNumberToCurrencyValidationResponseForUi extends ConvertNumberToCurrencyValidationResponse {
    affectedAdHocFilters: ConvertFieldTypeAffectedAdHocFilter[];
    affectedWflAutomationRules: ConvertFieldTypeAffectedWflAutomationRules[];
  }

  export interface NominationReportFieldResponse {
    nominationReferenceFields: ApplicationRefFieldResponse[];
    employeeApplicantInfo: Record<string, string>;
    nominatorInfo: Record<string, string>;
  }

  export type ConversionValidityInfo = ConvertFieldTypeValidationResponse|
    ConvertTextToDateValidationResponse|
    ConvertFieldResponseTypeValidationResponse|
    ConvertFieldTypeValidationResponse|
    ConvertNumberToCurrencyValidationResponseForUi|
    ConvertFieldToEncryptedAffectedAreasForUi|
    ConvertFieldMultiToSingleValidationResponseForUi;
}
