import { Injectable } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ApplicantFormForUI, BaseApplication } from '@core/typings/application.typing';
import { FileUploadForPDF, FormComponentForPdf, FormInfoForPDF, TableCsvForPdf } from '@core/typings/pdf.typing';
import { MAX_CDT_OPTIONS_TO_DISPLAY_ON_PDF, ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { environment } from '@environment';
import { DownloadAttachmentOptions } from '@features/application-download/download-application-pdf-modal/download-application-pdf-modal.component';
import { ApplicationFileService } from '@features/application-file/services/application-file.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { DownloadFormVisibility } from '@features/configure-forms/download-form-modal/download-form-modal.component';
import { BaseApplicationForLogic, ComponentWithAnswer, Form, FormAnswerValues, FormAudience, FormComponentGroup, FormComponentWithRefFieldData, FormComponentsByTab, FormDecisionTypes, FormDefinitionComponent, FormDefinitionForUi, StandardGmRequiredFields } from '@features/configure-forms/form.typing';
import { KeyValue } from '@features/custom-data-tables/custom-data-tables.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { DATE_SSO_FIELDS, EmployeeSSOFieldsData } from '@features/employee-sso-fields/employee-sso-fields.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { InKindService } from '@features/in-kind/in-kind.service';
import { LogicBuilderService } from '@features/logic-builder/logic-builder.service';
import { LogicColumn, LogicState, LogicValueFormatType, NestedPropColumn } from '@features/logic-builder/logic-builder.typing';
import { UserService } from '@features/users/user.service';
import { GoogleService } from '@yourcause/common';
import { SelectOption, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions, CurrencyValue } from '@yourcause/common/currency';
import { DateService } from '@yourcause/common/date';
import { YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { uniq } from 'lodash';
import { ComponentHelperService } from '../component-helper/component-helper.service';
import { ReportFieldService } from '../report-field/report-field.service';

@Injectable({ providedIn: 'root' })
export class FormHelperService {
  readonly componentBucketIdPrefix = 'form-component-bucket';

  constructor (
    private customDataTableService: CustomDataTablesService,
    private inKindService: InKindService,
    private currencyService: CurrencyService,
    private i18n: I18nService,
    private applicationFileService: ApplicationFileService,
    private clientSettingsService: ClientSettingsService,
    private reportFieldService: ReportFieldService,
    private userService: UserService,
    private logicBuilderService: LogicBuilderService,
    private componentHelper: ComponentHelperService,
    private portal: PortalDeterminationService,
    private formFieldHelperService: FormFieldHelperService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private googleService: GoogleService,
    private dateService: DateService
  ) { }

  /**
   * Pulls out the files for the forms passed in
   *
   * @param forms: forms to extract
   * @param attachmentsType: Which attachments should we download?
   * @returns file uploads
   */
  extractFilesForPdf (
    forms: FormInfoForPDF[],
    attachmentsType = DownloadAttachmentOptions.Form
  ) {
    const fileUploads: FileUploadForPDF[] = [];
    const fileNameMap: Record<string, number> = {};
    if (attachmentsType !== DownloadAttachmentOptions.None) {
      forms.forEach((form) => {
        const components = this.getVisibleFileUploads(form);
        components.forEach((component) => {
          if (component.answer) {
            this.mapFileUploads(
              component.answer,
              fileUploads,
              fileNameMap,
              attachmentsType
            );
          }
        });
      });
      const formWithSpecialHandling = forms.find((form) => {
        return !!form.specialHandling?.address1;
      });
      const specialHandlingFileUrl = formWithSpecialHandling?.specialHandling?.fileUrl;
      if (specialHandlingFileUrl) {
        const details = this.applicationFileService.breakDownloadUrlDownToObject(
          specialHandlingFileUrl
        );
        this.mapFileUploads(
          [
            new YcFile(
              details.fileName,
              null,
              specialHandlingFileUrl,
              +details.fileId
            )
          ],
          fileUploads,
          fileNameMap,
          attachmentsType
        );
      }
    }

    return fileUploads;
  }

  /**
   * Maps the file uploads
   *
   * @param answer: form answer
   * @param fileUploads: file uploads
   * @param fileNameMap: file name map
   * @param attachmentsType: Attachment type
   */
  mapFileUploads (
    answer: FormAnswerValues,
    fileUploads: FileUploadForPDF[],
    fileNameMap: Record<string, number>,
    attachmentsType: DownloadAttachmentOptions
  ) {
    let alreadyHave = false;
    if (attachmentsType === DownloadAttachmentOptions.Form) {
      (answer as YcFile<File>[] || []).forEach((ycFile) => {
        const file = this.applicationFileService.breakDownloadUrlDownToObject(ycFile.fileUrl);
        const adaptedFile: FileUploadForPDF = {
          ...file,
          fileUrl: ''
        };
        alreadyHave = this.checkIfAlreadyHaveFile(
          adaptedFile.fileId,
          fileUploads
        );
        const passed = this.addToFileNameMap(alreadyHave, adaptedFile, fileNameMap);
        if (passed) {
          fileUploads.push(adaptedFile);
        }
      });
    }
  }

  /**
   * Adds the file name to the map if we don't already have it
   *
   * @param alreadyHave: already have file name
   * @param file: file for comparison
   * @param fileNameMap: file name map
   * @returns if passed
   */
  addToFileNameMap (
    alreadyHave: boolean,
    file: FileUploadForPDF|TableCsvForPdf,
    fileNameMap: Record<string, number>
  ): boolean {
    if (!alreadyHave) {
      if (fileNameMap[file.fileName]) {
        ++fileNameMap[file.fileName];
        file.fileName = `${file.fileName.split('.').slice(0, -1).join('.')}_${fileNameMap[file.fileName]}.${file.fileName.split('.').pop()}`;
      }
      fileNameMap[file.fileName] = fileNameMap[file.fileName] || 1;

      return true;
    }

    return false;
  }

  /**
   * Checks if we already have a file
   *
   * @param thisFileId: this file upload ID
   * @param fileUploads: all file uploads
   * @returns if we already have the file
   */
  checkIfAlreadyHaveFile (
    thisFileId: string|number,
    fileUploads: FileUploadForPDF[]
  ) {
    let alreadyHave = false;
    if (!!thisFileId) {
      fileUploads.forEach((upload) => {
        if (+thisFileId === +upload.fileId) {
          alreadyHave = true;
        }
      });
    }

    return alreadyHave;
  }


  /**
   * Gets the visible file uploads
   *
   * @param form form to investigate
   * @returns visible file uploads
   */
  getVisibleFileUploads (form: FormInfoForPDF) {
    const components: ComponentWithAnswer[] = [];
    const tabs = form.formDefinition;
    tabs.forEach((tab) => {
      const compsToCheck = (tab as FormDefinitionForUi).components;
      this.componentHelper.eachComponent(compsToCheck, (component) => {
        let answer: FormAnswerValues;
        const noNestedComponents = !component.components ||
          !component.components.length;
        const isRefField = this.componentHelper.isReferenceFieldComp(component.type);
        let isValidType = false;
        if (isRefField) {
          const field = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
          answer = form.referenceFields[field.key];
          const isTable = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table;
          const isFileUpload = field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload;
          isValidType = isFileUpload;
          if (isTable) {
            const columns = this.formFieldTableAndSubsetService.getColumnsForTable(
              field.referenceFieldId,
              component.hiddenTableColumnKeys,
              component.labelOverrideMap
            );
            columns.filter((column) => {
              return column.referenceField.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload;
            }).forEach((fileField) => {
              const comp = this.componentHelper.getComponentFromTableColumn(
                fileField,
                component.labelOverrideMap,
                component.requiredOverrideKeys
              );
              let tableFileAnswer: FormAnswerValues;
              const tableResponseRows = form.referenceFields[field.key] as ReferenceFieldsUI.TableResponseRowForUi[];
              tableResponseRows?.forEach((row) => {
                row.columns.forEach((column) => {
                  if (column.referenceFieldId === fileField.referenceFieldId) {
                    tableFileAnswer = column.value;
                  }
                });
              });

              const compToAdd = {
                ...comp,
                answer: tableFileAnswer
              };
              components.push(compToAdd);
            });
          }
        }
        if (
          isValidType &&
          noNestedComponents &&
          this.componentHelper.isCompVisible(component)
        ) {
          const compToAdd = {
            ...component,
            answer
          };
          components.push(compToAdd);
        }
      }, true);
    });

    return components;
  }

  /**
   * Gets PDF Components for only visible comps
   *
   * @param conditionalVisibilityState: the conditional visibility state
   * @param formDefinition: form definition
   * @returns visible pdf components aray
   */
  getPdfComponentsForOnlyVisible (
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>,
    formDefinition: FormDefinitionForUi[]
  ): FormComponentsByTab[] {
    const visibleTabs = this.getVisibleTabs(conditionalVisibilityState, formDefinition);
    const formComponentsByTab: FormComponentsByTab[] = [];
    visibleTabs.forEach((tab) => {
      const components: FormDefinitionComponent[] = [];
      this.componentHelper.eachComponent((tab as FormDefinitionForUi).components, (component) => {
        this.componentHelper.updateApplicableComponentsArray(
          component,
          false,
          components
        );
      }, true);

      formComponentsByTab.push({
        tabName: (tab as FormDefinitionForUi).tabName,
        components: this.mapComponentsForPdf(components)
      });
    });

    return formComponentsByTab;
  }

  /**
   * Gets PDF components for the entire form
   *
   * @param formDefinition: the form definition
   * @param skipVisibility: skip visibility?
   * @returns components array based on arguments
   */
  getPdfComponentsForAll (
    formDefinition: FormDefinitionForUi[],
    skipVisibility: boolean
  ): FormComponentsByTab[] {
    const formComponentsByTab: FormComponentsByTab[] = [];
    formDefinition.map((tab) => {
      const applicableComponents: FormDefinitionComponent[] = [];
      this.componentHelper.eachComponent(tab.components, (formComponent) => {
        this.componentHelper.updateApplicableComponentsArray(
          formComponent,
          skipVisibility,
          applicableComponents
        );
      }, true);

      formComponentsByTab.push({
        tabName: tab.tabName,
        components: this.mapComponentsForPdf(applicableComponents)
      });
    });


    return formComponentsByTab;
  }

  /**
   * Prepares InKind on a form
   *
   * @param formDefinition: the form definition
   */
  async prepareInKindForForm (formDefinition: FormDefinitionForUi[]) {
    const components = this.componentHelper.getAllComponents(formDefinition);
    const inKindComp = components.find((comp) => {
      return comp.type === 'inKindItems';
    });
    if (!!inKindComp) {
      const availableItems = inKindComp.items;
      if (availableItems?.length > 0) {
        const detailedItems = await this.inKindService.getItemsById(
          uniq(availableItems),
          undefined,
          this.userService.getCurrentUserCulture()
        );
        inKindComp.inKindItemsForPdf = detailedItems.map((item) => {
          return {
            label: item.name,
            value: item.identification
          };
        });
      } else {
        inKindComp.inKindItemsForPdf = [];
      }
    }
  }

  /**
   * Prepares all components for render
   *
   * @param formDefinitions: the form definitions
   * @param formIds: the form ids
   */
  async prepareComponentsForRenderForm (
    formDefinitions: FormDefinitionForUi[][],
    formIds: number[]
  ) {
    let components: FormDefinitionComponent[] = [];
    formDefinitions.forEach((formDefinition) => {
      const formComps = this.componentHelper.getAllComponents(formDefinition);
      components = [
        ...components,
        ...formComps
      ];
    });
    const {
      cdtsToFetchFromTable,
      hasTableAddressField
    } = await this.prepareTablesAndSubsetsFromComponents(components);
    const clientId = this.clientSettingsService.clientSettings.clientId || null;
    await Promise.all([
      this.prepareCdtsAndSubsetsFromComponents(
        components,
        cdtsToFetchFromTable,
        formIds,
        clientId
      ),
      this.prepareAddressFields(components, hasTableAddressField)
    ]);
  }

  async prepareAddressFields (
    components: FormDefinitionComponent[],
    hasTableAddressField: boolean
  ) {
    const hasAddressFields = this.formFieldHelperService.hasAddressFields(components);
    if (hasAddressFields || hasTableAddressField) {
      await this.googleService.resolve(environment.googleApiKey, this.userService.getCurrentUserCulture());
    }
  }

  /**
   * Prepares CDT and Subset components
   *
   * @param components: the components
   * @param additionalGuids: additional cdt guids
   * @param formIds: form ids
   * @param clientId: client id
   */
  async prepareCdtsAndSubsetsFromComponents (
    components: FormDefinitionComponent[],
    additionalGuids: string[],
    formIds: number[],
    clientId?: number
  ) {
    const guids: string[] = additionalGuids;
    const subsetIds: number[] = [];
    components.forEach((component) => {
      const foundField = this.formFieldHelperService.getReferenceFieldFromCompType(
        component.type
      );
      if (foundField?.customDataTableGuid) {
        guids.push(foundField.customDataTableGuid);
      }
      if (foundField?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
        subsetIds.push(foundField.referenceFieldId);
      }
    });
    await this.customDataTableService.setCdtOptionsInBulkForFormIds(
      guids,
      this.userService.getCurrentUserCulture(),
      formIds,
      clientId,
      true
    );
  }

  /**
   * Prepares Tables and Subset Components
   *
   * @param components: the components
   * @returns cdt guids to fetch and if there is an address field on any tables
   */
  async prepareTablesAndSubsetsFromComponents (
    components: FormDefinitionComponent[]
  ) {
    const tableRefIds = this.formFieldTableAndSubsetService.getTableAndSubsetIds(components);
    const tableFields = await this.formFieldTableAndSubsetService.setAllTableAndSubsetColumnsOnForm(
      tableRefIds
    );
    const cdtsToFetchFromTable: string[] = [];
    let hasTableAddressField = false;
    tableFields.forEach((fields) => {
      fields.forEach((field) => {
        const found = this.formFieldHelperService.referenceFieldMapById[
          field.referenceFieldId
        ];
        if (!!found?.customDataTableGuid) {
          cdtsToFetchFromTable.push(found.customDataTableGuid);
        }
        if (found?.type === ReferenceFieldsUI.ReferenceFieldTypes.Address) {
          hasTableAddressField = true;
        }
      });
    });

    return {
      cdtsToFetchFromTable,
      hasTableAddressField
    };
  }

  /**
   * Maps the components for PDF
   *
   * @param components: the components
   * @returns mapped components for pdf
   */
  mapComponentsForPdf (
    components: FormDefinitionComponent[]
  ): FormComponentWithRefFieldData[] {
    return components.map((component) => {
       const refFieldKey = this.componentHelper.getRefFieldKeyFromCompType(
        component.type
      );
      const foundField = this.formFieldHelperService.getReferenceFieldByKey(
        refFieldKey
      );
      let referenceField: ReferenceFieldAPI.ReferenceFieldPdfData;
      let visibleColumns: ReferenceFieldsUI.TableFieldForUi[] = [];
      if (foundField) {
        let options: KeyValue[] = [];
        if (foundField.customDataTableGuid) {
          options = this.customDataTableService.customDataTableOptionsMap[foundField.customDataTableGuid];
          options = (options || []).filter((opt) =>  opt.inUse);
        }
        const isTableOrSubset = [
          ReferenceFieldsUI.ReferenceFieldTypes.Table,
          ReferenceFieldsUI.ReferenceFieldTypes.Subset
        ].includes(foundField.type);
        if (isTableOrSubset) {
          visibleColumns = this.formFieldTableAndSubsetService.getVisibleTableColumns(
            foundField.referenceFieldId,
            component.hiddenTableColumnKeys || [],
            component.labelOverrideMap || {},
            {},
            true
          );
        }

        referenceField = {
          ...foundField,
          notAllOptionsDisplayed: options.length > MAX_CDT_OPTIONS_TO_DISPLAY_ON_PDF,
          options: options.map((opt) => {
            return {
              label: opt.value,
              value: opt.key
            };
          }).slice(0, MAX_CDT_OPTIONS_TO_DISPLAY_ON_PDF)
        };
      }

      return {
        ...component,
        referenceField,
        inKindItems: component.inKindItemsForPdf,
        visibleColumns,
        hideLabel: ['columns', 'well', 'table'].includes(component.type)
      };
    });
  }

  /**
   * Get the applicable components to show on PDF
   *
   * @param formDefinition: the form definition
   * @param referenceFields: reference field responses
   * @returns components applicable for pdf
   */
  getApplicableComponentsForPdf (
    formDefinition: FormDefinitionForUi[],
    referenceFields: ReferenceFieldsUI.RefResponseMap
  ): FormComponentForPdf[] {
    const applicableComponents: FormDefinitionComponent[] = [];
    formDefinition.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (formComponent) => {
        this.componentHelper.updateApplicableComponentsArray(
          formComponent,
          false,
          applicableComponents
        );
      }, true);
    });

    return applicableComponents.map<FormComponentForPdf>((comp) => {
      return this.mapComponentToComponentForPDF(comp, referenceFields);
    });
  }

  /**
   *
   * @param comp: form component
   * @param referenceFields: reference field answers
   * @returns the form component for PDF
   */
  mapComponentToComponentForPDF (
    comp: FormDefinitionComponent,
    referenceFields: ReferenceFieldsUI.RefResponseMap
  ): FormComponentForPdf {
    const referenceField = this.formFieldHelperService.getReferenceFieldFromCompType(
      comp.type
    );
    const visibleColumns = this.formFieldTableAndSubsetService.getVisibleTableColumns(
      referenceField?.referenceFieldId,
      comp.hiddenTableColumnKeys,
      comp.labelOverrideMap
    );
    const numberOfCols = this.formFieldTableAndSubsetService.getNumberOfColumns(
      referenceField,
      comp.hiddenTableColumnKeys
    );
    let totalTableRows = 0;
    if (referenceField?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      totalTableRows = numberOfCols;
    } else if (referenceField?.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
      const rows = referenceFields[referenceField.key] as ReferenceFieldsUI.TableResponseRowForUi[];
      totalTableRows = rows.length;
    }

    return {
      ...comp,
      referenceField,
      isLayoutComponent: this.componentHelper.isLayoutComponent(comp.type),
      isTotaled: this.componentHelper.getIsTableTotaled(visibleColumns),
      totalTableRows,
      visibleColumns
    };
  }

  /**
   * Returns whether or not the field should be allowed to save data.
   *
   * @param field Reference field to be checked
   * @param isManagerForm Boolean indicating the audience of the form the field exists on
   */
  getSaveIsDisabled (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    isManagerForm: boolean
  ) {
    const applicantFieldOnManagerForm = field?.formAudience === FormAudience.APPLICANT &&
      isManagerForm;
    const managerFieldOnApplicantForm = field?.formAudience === FormAudience.MANAGER &&
      !isManagerForm;

    const saveDisabled = applicantFieldOnManagerForm || managerFieldOnApplicantForm;

    return saveDisabled;
  }

  /**
   * Gets the required reference field keys on submission
   *
   * @param conditionalVisibilityState: the conditional visibility state
   * @param formDefinition: the form definition
   * @returns the required reference field keys
   */
  getRequiredReferenceFieldKeys (
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>,
    formDefinition: FormDefinitionForUi[]
  ) {
    const visibleTabs = this.getVisibleTabs(conditionalVisibilityState, formDefinition);
    const requiredComps = this.componentHelper.getRequiredComponents(visibleTabs);

    return requiredComps.map((comp) => {
      return this.componentHelper.getRefFieldKeyFromCompType(comp.type);
    }).filter((key) => {
      return !!key;
    });
  }

  /**
   * Gets the visible tabs
   *
   * @param conditionalVisibilityState: the conditional visibility state
   * @param formDefinition: the form definition
   * @returns the visible tabs
   */
  getVisibleTabs (
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>,
    formDefinition: FormDefinitionForUi[]
  ) {
    return  conditionalVisibilityState ?
      this.filterHiddenTabs(
        formDefinition,
        conditionalVisibilityState
      ) :
      formDefinition;
  }

  /**
   * Gets the required standard fields on submission
   *
   * @param formDefinition: the form definition
   * @returns the required standard fields
   */
  getRequiredStandardFields (
    formDefinition: FormDefinitionForUi[]
  ): StandardGmRequiredFields {
    const requiredComps = this.componentHelper.getRequiredComponents(formDefinition);
    let reviewerRecommendedFundingAmountRequired = false;
    let decisionRequired = false;
    let amountRequestedRequired = false;
    const careOfRequired = false;
    let paymentDesignationRequired = false;

    requiredComps.forEach((comp) => {
      switch (comp.type) {
        case 'amountRequested':
          amountRequestedRequired = true;
          break;
        case 'decision':
          decisionRequired = true;
          break;
        case 'designation':
          paymentDesignationRequired = true;
          break;
        case 'reviewerRecommendedFundingAmount':
          reviewerRecommendedFundingAmountRequired = true;
          break;
      }
    });

    return {
      reviewerRecommendedFundingAmountRequired,
      decisionRequired,
      amountRequestedRequired,
      careOfRequired,
      paymentDesignationRequired
    };
  }

  /**
   * Gets the table and subset ids from the form definition
   *
   * @param formDefinitions: form definition
   * @returns table and subset ids
   */
  getTableAndSubsetIdsFromFormDefinition (
    formDefinitions: FormDefinitionForUi[][]
  ) {
    const tableIds: number[] = [];
    formDefinitions.forEach((formDefinition) => {
      formDefinition.forEach((tab) => {
        this.componentHelper.eachComponent(tab.components, (component: FormDefinitionComponent) => {
          const field = this.formFieldHelperService.getReferenceFieldFromCompType(
            component.type
          );
          if (
            field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Table ||
            field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset
          ) {
            tableIds.push(field.referenceFieldId);
          }
        });
      });
    });

    return uniq(tableIds);
  }

  /**
   * Gets the decision options
   *
   * @param allowRecused: allow recused?
   * @param recuseValue: recuse value
   * @param truthyValue: truthy value
   * @param falsyValue: falsy value
   * @returns the decision options
   */
  getDecisionOptions (
    allowRecused: boolean,
    recuseValue: string,
    truthyValue: string,
    falsyValue: string
  ) {
    const options = [{
      label: truthyValue || this.i18n.translate('common:textYes'),
      value: FormDecisionTypes.Approve
    }, {
      label: falsyValue || this.i18n.translate('common:textNo'),
      value: FormDecisionTypes.Decline
    }];

    if (allowRecused) {
      return [
        ...options,
        {
          label: recuseValue || this.i18n.translate(
            'common:textRecused',
            {},
            'Recused'
          ),
          value: FormDecisionTypes.Recused
        }
      ];
    }

    return options;
  }

  /**
   * Gets the logic value format type from the component type
   *
   * @param compType: component type
   * @returns the format type
   */
  getLogicValueFormatType (
    compType: string
  ): LogicValueFormatType {
    const refField = this.formFieldHelperService.getReferenceFieldFromCompType(compType);
    if (refField) {
      switch (refField.type) {
        default:
        case ReferenceFieldsUI.ReferenceFieldTypes.TextArea:
        case ReferenceFieldsUI.ReferenceFieldTypes.TextField:
          return 'text';
        case ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes:
        case ReferenceFieldsUI.ReferenceFieldTypes.CustomDataTable:
        case ReferenceFieldsUI.ReferenceFieldTypes.Radio:
          return 'select';
        case ReferenceFieldsUI.ReferenceFieldTypes.Checkbox:
          return 'checkbox';
        case ReferenceFieldsUI.ReferenceFieldTypes.Date:
          return 'date';
        case ReferenceFieldsUI.ReferenceFieldTypes.Number:
          return 'number';
        case ReferenceFieldsUI.ReferenceFieldTypes.Currency:
          return 'currency';
      }
    } else {
      switch (compType) {
        default:
        case 'careOf':
        case 'designation':
          return 'text';
        case 'amountRequested':
        case 'reviewerRecommendedFundingAmount':
          return 'currency';
        case 'decision':
          return 'select';
      }
    }
  }

  /**
   * Gets the options and format value type given the component
   *
   * @param component: the componet
   * @returns typeahead options and logic value format type
   */
  async getOptionsAndFormatValueType (
    component: FormDefinitionComponent
  ) {
    let options: (TypeaheadSelectOption|SelectOption)[] = [];
    const key = this.componentHelper.getRefFieldKeyFromCompType(
      component.type
    );
    const referenceField = this.formFieldHelperService.allReferenceFields
      .find((field) => {
        return field.key === key;
      });
    const logicValueFormatType = this.getLogicValueFormatType(component.type);
    if (referenceField?.customDataTableGuid) {
      await this.customDataTableService.setCustomDataTableOptionsFromGuid(
        referenceField?.customDataTableGuid,
        this.userService.getCurrentUserCulture()
      );
      const parentMapVal = this.formFieldHelperService.parentPicklistValueMap[
        referenceField.parentReferenceFieldId
      ];
      options = this.customDataTableService.getTypeaheadOptionsForCdt(
        referenceField?.customDataTableGuid,
        null,
        referenceField.supportsMultiple,
        parentMapVal,
        false,
        []
      );
    } else if (component.type === 'decision') {
      options = this.getDecisionOptions(
        component.allowRecused,
        component.recuseValue,
        component.truthyValue,
        component.falsyValue
      );
    }

    return {
      options,
      logicValueFormatType
    };
  }

  /**
   * Filters out hidden tabs
   *
   * @param tabs: Fom definition tabs
   * @param conditionalVisibilityState: conditional visibility state
   * @returns the filtered tabs
   */
  filterHiddenTabs (
    tabs: FormDefinitionForUi[],
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>
  ) {
    return tabs.filter((_, index) => {
      const column: NestedPropColumn<BaseApplicationForLogic, 'tabs'> = ['tabs', index];
      const visible = this.logicBuilderService.getCurrentLogicValueOfColumn(
        column as LogicColumn<BaseApplicationForLogic>,
        conditionalVisibilityState
      ) ?? true;

      return visible;
    });
  }


  /**
   * Gets the value from the component
   *
   * @param component; the component
   * @param parentFields: parent fields
   * @param forceDefaultCurrency: are we forcing the user to enter in default currency?
   * @param returnCurrencyAmount: if we want to return the current currency amount instead of object
   * @returns the value / answer
   */
  getValueFromComponent (
    component: FormDefinitionComponent,
    parentFields: Partial<BaseApplication>,
    forceDefaultCurrency = false,
    returnCurrencyAmount = false
  ): FormAnswerValues {
    let value: FormAnswerValues = null;
    if (
      component.type !== 'button' &&
      !this.componentHelper.isLayoutComponent(component.type)
    ) {
      const field = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
      if (!!field) {
        value = parentFields.referenceFields[field.key] ??
          this.formFieldHelperService.getBlankValueForFormField(field, component, true);
        if (
          field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency &&
          returnCurrencyAmount
        ) {
          value = (value as CurrencyValue).amountForControl;
        }
      } else if (this.componentHelper.isEmployeeSsoComponent(component.type)) {
        const attr = component.type.split('-')[1] as keyof EmployeeSSOFieldsData;
        const isDate = DATE_SSO_FIELDS.includes(attr);
        value = parentFields.employeeInfo ? parentFields.employeeInfo[attr] : '';
        if (isDate) {
          if (!!value && !Array.isArray(value)) {
            value = this.dateService.formatDate(value);
          }
        }
      } else if (this.componentHelper.isReportFieldComp(component.type)) {
        if (!!component.reportFieldDataOptions) {
          value = this.reportFieldService.getReportFieldValue(
            component.reportFieldDataOptions,
            parentFields.reportFieldResponse,
            component
          );
        }
      } else if (component.type === 'amountRequested') {
        value = this.getValueForCurrency(
          parentFields.amountRequested,
          parentFields.currencyRequestedAmountEquivalent,
          parentFields.currencyRequested,
          forceDefaultCurrency,
          component.useCustomCurrency,
          component.customCurrency,
          component.defaultVal,
          parentFields.amountRequestedForEdit
        );
        if (returnCurrencyAmount) {
          return parentFields.amountRequestedForEdit;
        }
      } else if (component.type === 'reviewerRecommendedFundingAmount') {
        // Recommended funding is always stored in client default currency
        value = this.getValueForCurrency(
          parentFields.reviewerRecommendedFundingAmount,
          parentFields.reviewerRecommendedFundingAmount,
          this.clientSettingsService.defaultCurrency,
          true,
          component.useCustomCurrency,
          component.customCurrency,
          component.defaultVal,
          null
        );
        if (returnCurrencyAmount) {
          return value.amountForControl;
        }
      } else {
        value = (parentFields as any)[component.type] as FormAnswerValues;
      }
    } else {
      value = undefined;
    }

    return value;
  }

  /**
   * Find the value we should use for Amount Requested - current or default
   *
   * @param formDef: Form Definition
   * @param amount: Current Amount
   * @returns which number we should use - current or default
   */
  findDefaultAmountRequestedValOnForm (
    formDef: FormDefinitionForUi[],
    amount: number
  ) {
    const {
      foundComponent
    } = this.componentHelper.findComponentByKeyOrType(formDef, 'amountRequested', false);
    if (!!foundComponent && this.useDefaultValForAmountRequested(foundComponent.defaultVal, amount)) {
      return +foundComponent.defaultVal;
    }

    return amount;
  }

  /**
   * Gets the value for currency
   *
   * @param amountInDefaultCurrency: amount in default currency of client
   * @param amountEquivalent: the equivalent amount in currency requested
   * @param currencyRequested: currency requested
   * @param forceDefaultCurrency: force the user to enter amount in default currency
   * @param useCustomCurrency: setting on the component
   * @param customCurrency: currency required for component
   * @param defaultVal: default value of the component
   * @returns the value for component
   */
  getValueForCurrency (
    amountInDefaultCurrency: number,
    amountEquivalent: number,
    currencyRequested: string,
    forceDefaultCurrency: boolean,
    useCustomCurrency: CurrencyRadioOptions,
    customCurrency: string,
    defaultVal: string,
    amountRequestedForEdit: number // if this is populated but the over amounts are not, use this
  ) {
    const currencyOptions = this.currencyService.getCurrencyOptionsForComponent(
      currencyRequested,
      useCustomCurrency,
      customCurrency
    );
    const currency = this.componentHelper.getCurrencyForFormFieldControl(
      currencyRequested,
      useCustomCurrency,
      customCurrency,
      currencyOptions,
      this.clientSettingsService.defaultCurrency,
      this.userService.get('lastSelectedCurrency')
    );
    let amountForControl = forceDefaultCurrency ?
      amountInDefaultCurrency :
      amountEquivalent;
    if (amountForControl === 0 && !!amountRequestedForEdit) {
      amountForControl = amountRequestedForEdit;
    }
    if (this.useDefaultValForAmountRequested(defaultVal, amountForControl)) {
      amountForControl = +defaultVal;
    }

    return {
      amountInDefaultCurrency,
      amountEquivalent,
      amountForControl,
      currency
    };
  }

  /**
   * Should we use the default val for Amount Requested?
   *
   * @param defaultVal: Default Value
   * @param amountForControl: Current Amount in Control
   * @returns if we should use default val
   */
  useDefaultValForAmountRequested (
    defaultVal: string,
    amountForControl: number
  ) {
    return !!(defaultVal &&
      typeof defaultVal === 'string' &&
      !amountForControl &&
      amountForControl !== 0);
  }

  /**
   * Checks the form group validity
   *
   * @param formGroup: the form group
   * @param definition: the form definition
   * @returns is the form group valid?
   */
  checkFormGroupValidity (
    formGroup: FormComponentGroup,
    definition: FormDefinitionForUi
  ): boolean {
    if (formGroup.valid) {
      return true;
    } else {
      let isValid = true;
      this.componentHelper.eachComponent(definition.components, (comp) => {
        const compKey = comp.key;
        const control = formGroup.get(compKey);
        if (
          control &&
          control.invalid &&
          !comp.isHidden &&
          !comp.hiddenFromParent
        ) {
          isValid = false;
        }
      });

      return isValid;
    }
  }

  /**
   * Gets the reference field from the form key
   *
   * @param formKey: form key
   * @param formDef: form definition
   * @returns reference field that goes with that key
   */
  getRefFieldFromFormKey (
    formKey: string,
    formDef: FormDefinitionForUi[]
  ) {
    let foundType: string;
    formDef.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        if (comp.key === formKey) {
          foundType = comp.type;
        }
      });
    });
    if (foundType) {
      return this.formFieldHelperService.getReferenceFieldFromCompType(foundType);
    }

    return null;
  }

  /**
   * Gets the Form components by tab
   *
   * @param visibility: Download form visibility enum
   * @param form: the form
   * @param conditionalVisibilityState: the conditional visibility state
   * @returns the form components by tab array
   */
  getFormComponentsByTab (
    visibility: DownloadFormVisibility,
    form: Form,
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>
  ) {
    let formComponentsByTab: FormComponentsByTab[];
    if (visibility === DownloadFormVisibility.SHOW_ALL) {
      formComponentsByTab = this.getPdfComponentsForAll(
        form.formDefinition,
        true
      );
    } else {
      formComponentsByTab = this.getPdfComponentsForOnlyVisible(
        conditionalVisibilityState,
        form.formDefinition
      );
    }

    return formComponentsByTab;
  }

  /**
   * Returns whether we should force default currency in amount requested
   *
   * @param isManagerForm: is manager form?
   * @param isManagerEditingApplicantForm: is manager editing an applicant form?
   * @returns if we should force default currency on the form
   */
  shouldForceDefaultCurrencyInAmountRequested (
    isManagerForm: boolean,
    isManagerEditingApplicantForm: boolean
  ) {
    return isManagerForm || isManagerEditingApplicantForm;
  }

  /**
   * Can we toggle currency?
   *
   * @param currency: currency on form
   * @param isReadOnly: is the form disabled and read only
   * @param isForSetValue: is this for set value logic?
   * @returns if they can toggle currency
   */
  canToggleCurrency (
    currency: string,
    readOnly: boolean,
    isForSetValue: boolean
  ): boolean {
    if (!isForSetValue) {
      const defaultCurrency = this.clientSettingsService.defaultCurrency;
      if (
        this.portal.isManager &&
        readOnly === true && // this means it's read only and can toggle
        currency !== defaultCurrency
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Gets the default form given a list of forms
   */
  getDefaultForm (forms: ApplicantFormForUI[]) {
    const found = forms.find((form) => {
      return form.isDefault;
    });

    return found ? found : forms[0];
  }

  /**
   * Returns whether the given component has changes to display
   *
   * @param componentType: Component Type
   * @param refIdsChanged: Ref IDs that have changed
   * @param standardFieldsChanged: Standard Fields that have changed
   * @returns if the component has changes
   */
  getHasFieldChanges (
    componentType: string,
    isHidden: boolean,
    refIdsChanged: number[],
    standardFieldsChanged: ReferenceFieldsUI.StandardFieldTypes[]
  ) {
    let showFieldChange = false;
    if (!isHidden) {
      if (this.componentHelper.isReferenceFieldComp(componentType)) {
        const key = this.componentHelper.getRefFieldKeyFromCompType(componentType);
        const field = this.formFieldHelperService.getReferenceFieldByKey(key);
        showFieldChange = !!field && refIdsChanged?.includes(field.referenceFieldId);
      } else if (this.componentHelper.isStandardComponent(componentType)) {
        let compareVal: ReferenceFieldsUI.StandardFieldTypes;
        switch (componentType) {
          case 'amountRequested':
            showFieldChange = standardFieldsChanged?.includes(
              ReferenceFieldsUI.StandardFieldTypes.CASH_AMOUNT_REQUESTED
            ) || standardFieldsChanged?.includes(
              ReferenceFieldsUI.StandardFieldTypes.CASH_AMOUNT_REQUESTED_CURRENCY
            );
            break;
          case 'designation':
            compareVal = ReferenceFieldsUI.StandardFieldTypes.DESIGNATION;
            break;
          case 'inKindItems':
            compareVal = ReferenceFieldsUI.StandardFieldTypes.IN_KIND;
            break;
          case 'specialHandling':
            compareVal = ReferenceFieldsUI.StandardFieldTypes.ALTERNATE_ADDRES;
            break;
          case 'careOf':
            compareVal = ReferenceFieldsUI.StandardFieldTypes.CARE_OF;
            break;
        }
        if (!!compareVal) {
          showFieldChange = standardFieldsChanged?.includes(compareVal);
        }
      }
    }

    return showFieldChange;
  }
}

