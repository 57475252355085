import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AddressFormGroup } from '@core/components/address-block/address-block.component';
import { Address } from '@yourcause/common';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';

@Injectable({ providedIn: 'root' })
export class FormBuilderFactoryService {

  constructor (
    private formBuilder: TypeSafeFormBuilder
  ) { }

  createAddressGroup (address: Address): TypeSafeFormGroup<AddressFormGroup> {
    return this.formBuilder.group<AddressFormGroup>({
      name: address.name,
      address1: [address.address1, Validators.required],
      address2: address.address2,
      city: [address.city, Validators.required],
      stateProvRegCode: [address.stateProvRegCode, Validators.required],
      countryCode: [address.countryCode || 'US', Validators.required],
      postalCode: [address.postalCode, Validators.required]
    });
  }

  surfaceValidation (
    formGroup: UntypedFormGroup,
    controlName: string
  ) {
    if (formGroup) {
      const control = formGroup.get(controlName);
      if (control) {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    }
  }
}
