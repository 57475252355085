<yc-modal
  [modalHeader]="modalHeader"
  [modalSubHeader]="modalSubHeader"
  [primaryDisabled]="formGroup.invalid"
  [primaryButtonText]="'common:btnSave' | ycI18n: 'Save'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="closeModal.emit()"
  (onPrimaryClick)="closeModal.emit(formGroup.value)">
  <gc-address-block
    [addressFormGroup]="formGroup">
  </gc-address-block>
</yc-modal>