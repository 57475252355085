import { Component, Input, OnInit } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { SpinnerService } from '@core/services/spinner.service';
import { ValidatorsService } from '@core/services/validators.service';
import { REF_COMPONENT_TYPE_PREFIX, ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormChangesWithCompKey, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { FormFieldFileUploadService } from '@features/form-fields/services/form-field-file-upload.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { SimpleStringMap } from '@yourcause/common';
import { TypeSafeFormBuilder, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions } from '@yourcause/common/currency';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'gc-table-field-crud-modal',
  templateUrl: './table-field-crud-modal.component.html',
  styleUrls: ['./table-field-crud-modal.component.scss']
})
export class TableFieldCrudModalComponent extends YCModalComponent<
  {
    row: ReferenceFieldsUI.TableResponseRowForUi;
    addAnother: boolean;
    openNext: boolean;
    openPrevious: boolean;
  }
> implements OnInit {
  @Input() currentRow: ReferenceFieldsUI.TableResponseRowForUiMapped;
  @Input() tableReferenceFieldId: number;
  @Input() tableLabel: string;
  @Input() formId: number;
  @Input() isView = false;
  @Input() disableNextBtn: boolean;
  @Input() disablePreviousBtn: boolean;
  @Input() applicationId: number;
  @Input() applicationFormId: number;
  @Input() notAutoSave: boolean;
  @Input() translations: SimpleStringMap<string>;
  @Input() hiddenTableColumnKeys: string[];
  @Input() labelOverrideMap: Record<string, string> = {};
  @Input() requiredOverrideKeys: string[];

  modalHeader: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryDisabled: boolean;
  secondaryDisabled: boolean;
  FieldTypes = ReferenceFieldsUI.ReferenceFieldTypes;
  cdtItemsMap: Record<number, TypeaheadSelectOption[]> = {};
  responseMap: ReferenceFieldsUI.RefResponseMap = {};
  defaultCurrency = this.clientSettingsService.defaultCurrency;
  formattingData = this.currencyService.formattingData;
  currencyIcon = this.formattingData[this.defaultCurrency].symbol;
  ready = false;
  errorString: string;
  tableColumns: ReferenceFieldsUI.TableFieldForCrudUi[];
  validityMap: Record<number, boolean> = {};
  saving: boolean;
  hideModal = false;

  constructor (
    private i18n: I18nService,
    private customDataTableService: CustomDataTablesService,
    private clientSettingsService: ClientSettingsService,
    private currencyService: CurrencyService,
    private componentHelper: ComponentHelperService,
    private spinnerService: SpinnerService,
    private formHelperService: FormHelperService,
    private formFieldFileUploadService: FormFieldFileUploadService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private formBuilder: TypeSafeFormBuilder,
    private validatorsService: ValidatorsService
  ) {
    super();
  }

  get customDataTableOptionsMap () {
    return this.customDataTableService.customDataTableOptionsMap;
  }

  async ngOnInit () {
    this.setModalAttrs();
    this.setTableColumnsAndResponseMap();
    this.prepareCdtItemsMap();
    this.ready = true;
  }

  setModalAttrs () {
    if (this.currentRow) {
      this.modalHeader = this.i18n.translate(
        this.isView ?
          'common:textView' :
          'common:textEdit',
        {},
        this.isView ?
          'View' :
          'Edit'
      );
      this.primaryButtonText = this.i18n.translate(
        this.isView ?
          'common:btnNext' :
          'common:btnSave',
        {},
        this.isView ?
          'Next' :
          'Save'
      );
      if  (this.isView) {
        this.secondaryButtonText = this.i18n.translate(
          'common:textPrevious',
          {},
          'Previous'
        );
      }
    } else {
      this.modalHeader = this.i18n.translate(
        'GLOBAL:hdrAddNew',
        {},
        'Add New'
      );
      this.primaryButtonText = this.i18n.translate(
        'common:btnSave',
        {},
        'Save'
      );
      this.secondaryButtonText = this.i18n.translate(
        'GLOBAL:btnSaveAndAddAnother',
        {},
        'Save and add another'
      );
    }
  }

  setTableColumnsAndResponseMap () {
    const tableColumns = this.formFieldTableAndSubsetService.getColumnsForTable(
      this.tableReferenceFieldId,
      this.hiddenTableColumnKeys,
      this.labelOverrideMap
    );
    this.responseMap = tableColumns.reduce((acc, column) => {
      const foundResponse = this.currentRow?.columns.find((col) => {
        return col.referenceFieldId === column.referenceFieldId;
      });
      let value = foundResponse?.value;
      if (!value)  {
        const component = {
          type: `${REF_COMPONENT_TYPE_PREFIX}${column.referenceField.key}`,
          useCustomCurrency: CurrencyRadioOptions.USE_ONE_CURRENCY,
          customCurrency: this.clientSettingsService.defaultCurrency
        } as FormDefinitionComponent;
        value = this.formHelperService.getValueFromComponent(
          component,
          { referenceFields: {} },
          true
        );
      }

      return {
        ...acc,
        [column.referenceField.key]: value
      };
    }, {});
    this.tableColumns = tableColumns.map<ReferenceFieldsUI.TableFieldForCrudUi>((col) => {
      const refKey = col.referenceField.key;
      const component = this.componentHelper.getComponentFromTableColumn(
        col,
        this.labelOverrideMap,
        this.requiredOverrideKeys
      );
      const validators = this.validatorsService.getValidatorsForReferenceFieldComponent(
        component,
        col.referenceField,
        this.formFieldTableAndSubsetService.dataPointsMap,
        this.translations
      );

      return {
        ...col,
        component,
        formGroup: this.formBuilder.group({
          [refKey]: [this.responseMap[refKey], validators]
        })
      };
    });
  }

  prepareCdtItemsMap () {
    this.cdtItemsMap = this.customDataTableService.getCdtItemsMapForRow(
      this.tableColumns,
      this.currentRow,
      false
    );
  }

  adaptTableRows (): ReferenceFieldsUI.TableResponseRowForUi {
    return {
      rowId: this.currentRow?.rowId ?? null,
      columns: this.tableColumns.map((column) => {
        return {
          referenceFieldId: column.referenceFieldId,
          referenceFieldKey: column.referenceField.key,
          value: this.responseMap[column.referenceField.key],
          dateValue: '',
          currencyValue: '',
          addressValue: null,
          numericValue: null,
          file: null,
          files: [],
          applicationFormId: this.applicationFormId,
          applicationId: this.applicationId
        };
      })
    };
  }

  onValueChange (
    change: FormChangesWithCompKey,
    referenceFieldKey: string
  ) {
    if (change.updateFormGroup) {
      const foundCol = this.tableColumns.find((col) => {
        return col.referenceField.key === referenceFieldKey;
      });
      if (!!foundCol) {
        const control = foundCol.formGroup.get(referenceFieldKey);
        if (!!control) {
          control.setValue(change.value);
        }
      }
    }
    this.responseMap[referenceFieldKey] = change.value;
  }

  onValidChange (
    isValid: boolean,
    referenceFieldId: number
  ) {
    this.validityMap[referenceFieldId] = isValid;
    this.setDisabledAttrs();
  }

  setDisabledAttrs () {
    if (this.isView) {
      this.primaryDisabled = this.disableNextBtn;
      this.secondaryDisabled = this.disablePreviousBtn;
    } else {
      const allFieldsValid = this.tableColumns.every((column) => {
        return this.validityMap[column.referenceFieldId];
      });
      this.primaryDisabled = !allFieldsValid;
      this.secondaryDisabled = !allFieldsValid;
    }
  }

  async handleFileUploads () {
    const result = await this.formFieldFileUploadService.handleTableFileUploads(
      this.tableColumns,
      this.responseMap,
      this.applicationId,
      this.applicationFormId
    );

    if (!!result) {
      this.responseMap = result;
    }

    return !!result;
  }

  async onPrimaryClick () {
    if (this.isView) {
      /* Open Next Record */
      this.closeModal.emit({
        row: this.adaptTableRows(),
        addAnother: false,
        openNext: true,
        openPrevious: false
      });
    } else {
      this.handleFilesAndCloseModal(false);
    }
  }

  async handleFilesAndCloseModal (addAnother: boolean) {
    let passed = true;
    if (!this.notAutoSave) {
      this.spinnerService.startSpinner();
      this.saving = true;
      passed = await this.handleFileUploads();
      this.saving = false;
      this.spinnerService.stopSpinner();
    }
    if (passed) {
      /* Save and Exit */
      this.closeModal.emit({
        row: this.adaptTableRows(),
        addAnother,
        openNext: false,
        openPrevious: false
      });
    }
  }

  onSecondaryClick () {
    if (this.isView) {
      /* Open Previous Record */
      this.closeModal.emit({
        row: this.adaptTableRows(),
        addAnother: false,
        openNext: false,
        openPrevious: true
      });
    } else {
      this.handleFilesAndCloseModal(true);
    }
  }
}
